export enum ExpertType {
    Nutritionist = "nutritionist",
    Specialist = "specialist",
    Admin = "admin",
    User = "user,"
}

export enum InputType {
    Text = "text",
    Password = "password",
}

export const enum CompleteAccountPageState {
    NoEdit = "no-edit",
    Edit = "edit",
}

export const enum OrderType {
    DNATest = "DNA Test",
    Membership = "Membership",
    Consult = "Consulto",
    FarmaDNATest = "Farma | DNA Test",
    None = "Nessun ordine",
}

export const enum ExamFolder {
    DNATest = "dna_exam",
    BloodTest = "blood_exam",
    OtherTest = "other_exam",
}

export const enum SleepAnswer {
    Great = "Ottima, mi sveglio riposata/o e rigenerata/o ogni giorno",
    Good = "Buona, di solito mi sveglio riposata/o, ma talvolta ho notato dei disturbi nel sonno",
    NotGood = "Non ottimale, mi sveglio spesso durante la notte o non mi sento completamente riposata/o al mattino",
    Bad = "Bassa, ho difficoltà a dormire e mi sveglio frequentemente durante la notte, mi sento stanca/o al risveglio",
}

export const enum WaterAnswer {
    LessOneLiter = "Meno di un litro",
    OneLiterOneLiterHalf = "Tra un litro e un litro e mezzo",
    OneLiterHalfTwoLiters = "Tra un litro e mezzo e due litri",
    MoreTwoLiters = "Oltre due litri",
}

export const enum EnergyAnswer {
    Active = "No, mi sento energica/o e riposata/o la maggior parte del tempo",
    Medium = "Occasionalmente, soprattutto quando sono molto impegnata/o o stressata/o",
    Low = "Sì, spesso mi sento stanca/o anche dopo aver dormito a sufficienza",
}

export const enum TrainingAnswer {
    Everyday = "Ogni giorno",
    Frequently = "3-4 volte alla settimana",
    Sometimes = "1-2 volte alla settimana",
    Never = "Raramente o mai",
}

export const enum StressAnswer {
    Stressed = "Sì, spesso",
    Medium = "Qualche volta, dipende dal periodo",
    NoStress = "Raramente o mai",
}

export const enum QuizType {
    QuizQ = "Quiz",
    SurveyA = "Medical History",
    SurveyB = "Habits",
}

export const enum KeyParameter {
    Water = "water",
    Weight = "weight",
    Energy = "energy",
    WaistLine = "waistline",
    Sleep = "sleep",
    HipsLine = "hipsline",
    ArmCircunference = "arm_circumference",
    Training = "training",
    StressLevel = "stress_level",
}

export enum ShopifyItemSku {
    ExpertConsult = "Expert-consult",
    NutritionistConsult = "Nutritionist-consult",
    DnaTest = "0171002",
    DnaTestOld = "0171003",
}

export enum AppointmentStatus {
    Active = "active",
    Canceled = "canceled",
  }