import "./css/CreatePlanParameterWidget.css"

interface Props {
    name: string,
    image: string,
}

const CreatePlanParameterWidget: React.FC<Props> = ({
    name,
    image,
}) => {
  return (
    <div className="create-plan-parameter-widget-container">
      <div className="create-plan-parameter-widget-internal-container">
        <div className="create-plan-parameter-widget-heading">
            {name}
        </div>
        <div className="create-plan-parameter-widget-image">
            <img src={image}/>
        </div>
      </div>
    </div>
  )
}

export default CreatePlanParameterWidget
