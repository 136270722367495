import { KeyParameter } from "../../../utils/Enums"
import { handleParamenterImage, handleParamenterName } from "../../../utils/Functions"
import CreatePlanParameterWidget from "./CreatePlanParameterWidget"
import "./css/ImportantParametersWidget.css"

interface Props {
    onEditKeyParametersClick: () => void,
    onAddKeyParameterClick: () => void,
    selectedKeyParameters: KeyParameter[],
    emptyBoxNumber: number,
}

const ImportantParametersWidget: React.FC<Props> = ({
    onEditKeyParametersClick,
    onAddKeyParameterClick,
    selectedKeyParameters,
    emptyBoxNumber,
}) => {
    return (
        <div className="important-parameters-widget-full-container">
            <div className="important-parameters-widget-title-row">
                <div className="important-parameters-widget-title">
                    Parametri importanti
                </div>
                <div
                    className="important-parameters-widget-edit-button"
                    onClick={onEditKeyParametersClick}
                >
                    Modifica
                </div>
            </div>
            <div className="height-20" />
            <div className="important-parameters-widget-key-parameters-row">
                {
                    selectedKeyParameters.map(parameter => (
                        <CreatePlanParameterWidget
                            key={parameter}
                            name={handleParamenterName(parameter)}
                            image={handleParamenterImage(parameter)}
                        />
                    ))
                }
                {
                    emptyBoxNumber === 6 ?
                        <div
                            className="important-parameters-widget-key-parameters-add"
                            onClick={onAddKeyParameterClick}
                        >
                            +
                        </div> : null
                }
                {
                    emptyBoxNumber >= 5 ?
                        <div
                            className="important-parameters-widget-key-parameters-add"
                            onClick={onAddKeyParameterClick}
                        >
                            +
                        </div> : null
                }
                {
                    emptyBoxNumber >= 4 ?
                        <div
                            className="important-parameters-widget-key-parameters-add"
                            onClick={onAddKeyParameterClick}
                        >
                            +
                        </div> : null
                }
                {
                    emptyBoxNumber >= 3 ?
                        <div
                            className="important-parameters-widget-key-parameters-add"
                            onClick={onAddKeyParameterClick}
                        >
                            +
                        </div> : null
                }
                {
                    emptyBoxNumber >= 2 ?
                        <div
                            className="important-parameters-widget-key-parameters-add"
                            onClick={onAddKeyParameterClick}
                        >
                            +
                        </div> : null
                }
                {
                    emptyBoxNumber >= 1 ?
                        <div
                            className="important-parameters-widget-key-parameters-add"
                            onClick={onAddKeyParameterClick}
                        >
                            +
                        </div> : null
                }
            </div>
        </div>
    )
}

export default ImportantParametersWidget
