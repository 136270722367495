import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import CloseButton from "../../components/CloseButton"
import { ACCOUNT_LOGO, APPOINTMENTS_LOGO, BASE_URL, CUSTOMERS_LOGO_FOCUS, HOME_LOGO, RELATIVE_PATH_PLATFORM, RELATIVE_PATH_PLATFORM_ACCOUNT, RELATIVE_PATH_PLATFORM_APPOINTMENTS, RELATIVE_PATH_PLATFORM_CUSTOMERS, RELATIVE_PATH_PLATFORM_HOME } from "../../utils/Constants"
import { handleDate } from "../../utils/Functions"
import NavBar from "./components/NavBar"
import { Survey } from "./components/Survey"
import "./css/CustomersSingleCustomerPageAnswersPage.css"
import SurveyCard from "./widgets/components/SurveyCard"

const CustomersSingleCustomerPageAnswersPage = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const patient = location.state.patient
    const idToken = location.state.idToken
    const userId = location.state.userId
    const email = location.state.email

    const [isLoading, setIsLoading] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_HOME, { state: { userId, idToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM, { state: { userId, idToken } })
    }
    const onCustomersIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_CUSTOMERS, { state: { userId, idToken } })
    }
    const onApointmentsIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_APPOINTMENTS, { state: { userId, idToken, email } })
    }
    const onAccountIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_ACCOUNT, { state: { userId, idToken } })
    }

    useEffect(() => {
        callGetAllSurveysByUserIdApi()
    }, [])

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET all exams urls by type and user id
    async function callGetAllSurveysByUserIdApi() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/emr/${patient.userId}/surveys`, requestOptionsGET)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
            const newSurveys: Survey[] = []
            for (const survey of responseJson) {
                newSurveys.push({
                    type: survey.type,
                    createdAt: handleDate(survey.created_at),
                    qAndAs: survey.data,
                })
            }
            if (responseJson.length < 3) {
                setSurveyBMissing(true)
            }
            setSurveys(newSurveys)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
        setIsLoading(false)
    }

    const [surveys, setSurveys] = useState<Survey[]>([])
    const [surveyBMissing, setSurveyBMissing] = useState(false)

    return (
        <div className='App Platform relative'>
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                customersLogo={CUSTOMERS_LOGO_FOCUS}
                onCustomersIconClick={onCustomersIconClick}
                apointmentsLogo={APPOINTMENTS_LOGO}
                onApointmentsIconClick={onApointmentsIconClick}
                accountLogo={ACCOUNT_LOGO}
                onAccountIconClick={onAccountIconClick}
                isLoading={isLoading}
            />
            <div className="platform-experts-no-navbar-container">
                <div className="platform-experts-customers-single-patient-answer-page-container">
                    <div className="platform-experts-customers-single-patient-answer-page-title-row">
                        <div className="platform-experts-customers-single-patient-answer-page-title">
                            Le risposte di {patient.name} {patient.familyName}
                        </div>
                        <CloseButton
                            onClick={() => navigate("/platform-experts/customers/single-patient-page", { state: { patient, userId, idToken, email } })}
                        />
                    </div>
                    <div className="platform-experts-customers-single-patient-answer-page-content">
                        <div className="platform-experts-customers-single-patient-answer-page-content-container">
                            {/* <div className="platform-experts-customers-single-patient-answer-page-content-updates-section">
                                <div className="height-35" />
                                <div className="platform-experts-customers-single-patient-answer-page-content-updates-heading-row">
                                    <div className="platform-experts-customers-single-patient-answer-page-content-updates-heading">
                                        Ultimi aggiornamenti
                                    </div>
                                    <div className="platform-experts-customers-single-patient-answer-page-content-updates-see-all">
                                        Vedi tutti
                                    </div>
                                </div>
                                <div className="height-35" />
                                <div className="platform-experts-customers-single-patient-answer-page-single-question-line"></div>
                                <div className="platform-experts-customers-single-patient-answer-page-single-question-line"></div>
                                <div className="platform-experts-customers-single-patient-answer-page-single-question-line"></div>
                                <div className="height-35" />
                            </div> */}
                            <div>
                                <div className="height-35" />
                                <div className="platform-experts-customers-single-patient-answer-page-content-updates-heading">
                                    Questionari
                                </div>
                                <div className="height-20"/>
                                <div className="platform-experts-customers-single-patient-answer-page-content-survey-cards-row">
                                    {
                                        surveys.map(survey => (
                                            <SurveyCard
                                                key={surveys.indexOf(survey)}
                                                survey={survey}
                                                onCardClick={() => navigate(`single-survey/${survey.type}`, {state: {idToken, userId, patient, email, survey}})}
                                            />
                                        ))
                                    }
                                    {
                                        surveyBMissing ? 
                                        <div className="platform-experts-customers-single-patient-answer-page-survey-card-placeholder"/>: null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomersSingleCustomerPageAnswersPage
