import DownloadButton from "../../../components/DownloadButton"
import SingleLineDivider from "../../../components/SingleLineDivider"
import { KeyParameter } from "../../../utils/Enums"
import { handleParamenterImage, handleParamenterName } from "../../../utils/Functions"
import { Plan } from "../components/Plan"
import ParameterWidget from "./ParameterWidget"
import "./css/PlanWidget.css"

interface Props {
    onEditPlanClick: () => void,
    goal: string,
    weight: string,
    waterIntake: string,
    waterIntakeAnswer: string,
    energy: string,
    energyAnswer: string,
    waistline: string,
    sleep: string,
    sleepAnswer: string,
    training: string,
    trainingAnswer: string,
    stress: string,
    stressAnswer: string,
    armCinfumference: string,
    hipsline: string,
    currentPlan: Plan,
    expertComment: string,
    expertKeyPoints: string[] | undefined,
    keyParameters: KeyParameter[] | undefined,
}

const PlanWidget: React.FC<Props> = ({
    onEditPlanClick,
    goal,
    weight,
    waterIntake,
    waterIntakeAnswer,
    energy,
    energyAnswer,
    waistline,
    sleep,
    sleepAnswer,
    training,
    trainingAnswer,
    stress,
    stressAnswer,
    armCinfumference,
    hipsline,
    currentPlan,
    expertComment,
    expertKeyPoints,
    keyParameters,
}) => {
    const handleParameterValue = (parameter: KeyParameter) => {
        switch (parameter) {
            case KeyParameter.Energy:
                return energy
            case KeyParameter.Sleep:
                return sleep
            case KeyParameter.WaistLine:
                return waistline + " cm"
            case KeyParameter.Water:
                return waterIntake
            case KeyParameter.Weight:
                return weight + " kg"
            case KeyParameter.HipsLine:
                return hipsline + " cm"
            case KeyParameter.ArmCircunference:
                return armCinfumference + " cm"
            case KeyParameter.Training:
                return training
            case KeyParameter.StressLevel:
                return stress
        }
    }

    return (
        <div className="plan-widget-container">
            <div className="plan-widget-title-row">
                <div className="plan-widget-title-container">
                    <div className="plan-widget-title">Piano alimentare attuale</div>
                    <div
                        className="plan-widget-edit-button"
                        onClick={onEditPlanClick}
                    >
                        Modifica
                    </div>
                </div>
                <div className="plan-widget-publishing-date">
                    Aggiornato il {currentPlan.spanDate}
                </div>
            </div>
            <div className="height-20" />
            <div className="plan-widget-your-goal-container">
                <div className="plan-widget-your-goal-internal-container">
                    {goal}
                </div>
            </div>
            <div className="height-20" />
            {
                keyParameters !== undefined ?
                    <div className="plan-widget-parameters-container">
                        {
                            keyParameters.map(parameter => (
                                <ParameterWidget
                                    key={parameter}
                                    name={handleParamenterName(parameter)}
                                    image={handleParamenterImage(parameter, energyAnswer, waterIntakeAnswer, sleepAnswer, stressAnswer, trainingAnswer)}
                                    value={handleParameterValue(parameter)}
                                />
                            ))
                        }
                        {/* <ParameterWidget
                    name="Peso"
                    image="/images/emojis/weight-icon.svg"
                    value={weight + " kg"}
                />
                <ParameterWidget
                    name="Acqua"
                    image="/images/emojis/water-icon.svg"
                    value={waterIntake}
                />
                <ParameterWidget
                    name="Energia"
                    image="/images/emojis/energy-icon.svg"
                    value={energy}
                />
                <ParameterWidget
                    name="Girovita"
                    image="/images/emojis/girovita-icon.svg"
                    value={waistline + " cm"}
                />
                <ParameterWidget
                    name="Sonno"
                    image="/images/emojis/pillow-icon.svg"
                    value={sleep}
                /> */}
                    </div>
                    : null
            }
            <div className="height-20" />
            <div className="plan-widget-tips-area">
                <div className="plan-widget-tip-one">
                    {expertKeyPoints !== undefined ?
                        <div className="plan-widget-tip-one-internal">
                            <div className="plan-widget-tips-heading">
                                {expertKeyPoints.length} cose importanti da tenere a mente
                            </div>
                            <div className="plan-widget-tips-expert">
                                Dal tuo Nutrizionista
                            </div>
                            <div className="plan-widget-expert-key-points-container">
                                <div className="height-20" />
                                <ul className="plan-widget-key-points-text">
                                    {expertKeyPoints.map(keyPoint => (
                                        <li 
                                            className="plan-widget-key-point"
                                            key={keyPoint}
                                        >
                                            {keyPoint}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div> : null
                    }
                </div>
                <div className="plan-widget-tip-two">
                    <div className="plan-widget-tip-two-internal">
                        <div className="plan-widget-tips-heading">
                            Il commento del piano
                        </div>
                        <div className="plan-widget-tips-expert">
                            Dal tuo Nutrizionista
                        </div>
                        <div className="plan-widget-expert-comment-container">
                            <div className="height-20" />
                            <div className="plan-widget-expert-comment-text">
                                {expertComment}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="height-20" />
            <div className="plan-widget-plan-card-container">
                <div className="plan-widget-plan-card-upper-element-container">
                    <iframe
                        src={currentPlan.url + "#toolbar=0"}
                        width="100%"
                        height="100%"
                    />
                </div>
                <div className="plan-widget-plan-card-bottom-element-container">
                    <div className="plan-widget-plan-card-bottom-element-internal-container">
                        <div>
                            <div className="plan-widget-plan-card-bottom-element-date">
                                {currentPlan.spanDate}
                            </div>
                            <div className="plan-widget-plan-card-bottom-element-text">
                                Il tuo piano attuale
                            </div>
                        </div>
                        <DownloadButton
                            url={currentPlan.url}
                        />
                    </div>
                </div>
            </div>
            <div className="height-20" />
            <SingleLineDivider />
            <div className="height-20" />
        </div>
    )
}

export default PlanWidget
