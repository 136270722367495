import { Exam } from "../pages/platform/components/Exam";

export const BASE_URL = process.env.REACT_APP_BASE_URL || "https://0k1h4aqzf5.execute-api.eu-central-1.amazonaws.com";

export const PHARMACIES_CONFIRMATION_NUMBER = "FARMA6794LH"

export const SHOW_PASSWORD_ICON_DISABLED = "/images/symbols/show-password-disabled.svg"
export const SHOW_PASSWORD_ICON = "/images/symbols/show-password.svg"
export const HIDE_PASSWORD_ICON_DISABLED = "/images/symbols/hide-password-disabled.svg"
export const HIDE_PASSWORD_ICON = "/images/symbols/hide-password.svg"

export const multiplidi3 = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48, 51, 54, 57, 60]

export const fakeExam: Exam = {
    date: "fakedata",
    name: "fakename",
    url: "fakeurl"
}

export const HOME_LOGO_FOCUS = "/images/platformLogos/home-logo-focus.svg"
export const HOME_LOGO = "/images/platformLogos/home-logo.svg"
export const CUSTOMERS_LOGO_FOCUS = "/images/platformLogos/customers-logo-focus.svg"
export const CUSTOMERS_LOGO = "/images/platformLogos/customers-logo.svg"
export const APPOINTMENTS_LOGO = "/images/platformLogos/apointments-logo.svg"
export const APPOINTMENTS_LOGO_FOCUS = "/images/platformLogos/apointments-logo-focus.svg"
export const ACCOUNT_LOGO_FOCUS = "/images/platformLogos/account-logo-focus.svg"
export const ACCOUNT_LOGO = "/images/platformLogos/account-logo.svg"

export const RELATIVE_PATH_PLATFORM_HOME = "/platform-experts/home"
export const RELATIVE_PATH_PLATFORM = "/platform-experts"
export const RELATIVE_PATH_PLATFORM_CUSTOMERS = "/platform-experts/customers"
export const RELATIVE_PATH_PLATFORM_APPOINTMENTS = "/platform-experts/apointments"
export const RELATIVE_PATH_PLATFORM_ACCOUNT = "/platform-experts/account"