import { Patient } from "../components/Patient"
import "./css/CustomerWidget.css"

interface Props {
    patient: Patient,
    onCardClick: () => void,
}

const CustomerWidget: React.FC<Props> = ({
    patient,
    onCardClick,
}) => {
    return (
        <div
            className="customer-widget-container"
            onClick={onCardClick}
        >
            <div className="customer-widget-internal-container">
                <div className="customer-widget-left-element">
                    <div className="customer-widget-name">{patient.name} {patient.familyName}</div>
                    <div className="customer-widget-email">{patient.email}</div>
                </div>
                <div className="customer-widget-right-element">
                    <div className="customer-widget-tax-code">{patient.swabCode}</div>
                    <div className="customer-widget-plan">{patient.orderType}</div>
                </div>
            </div>
        </div>
    )
}

export default CustomerWidget
