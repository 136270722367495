import "./css/DropBox.css"

interface Props {
    title: string,
    date: string,
    onButtonClick: () => void,
}

const DropBox: React.FC<Props> = ({
    title,
    date,
    onButtonClick,
}) => {
    return (
        <div className="drop-box-external-container">
            <div className="drop-box-internal-container">
                <div className="drop-box-first-line">
                    <div className="drop-box-title">
                        {title}
                    </div>
                    <div className="drop-box-date">
                        {date}
                    </div>
                </div>
                <button
                    className="drop-box-button"
                    onClick={onButtonClick}
                >
                    Apri
                </button>
            </div>
        </div>
    )
}

export default DropBox
