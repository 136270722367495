import { QuestionsAndAnswers } from "../../components/QuestionsAndAnswers"
import "./css/AnswerCard.css"

interface Props {
    qAndA: QuestionsAndAnswers,
    date: string,
}

const AnswerCard: React.FC<Props> = ({
    qAndA,
    date,
}) => {
    return (
        <div className="answer-card-container">
            <div className="answer-card-internal-container">
                <div className="answer-card-left-element-container">
                    <div className="answer-card-question">
                        {qAndA.q}
                    </div>
                    <div className="answer-card-answer">
                        {qAndA.a}
                    </div>
                </div>
                <div className="answer-card-date">
                    {date}
                </div>
            </div>
        </div>
    )
}

export default AnswerCard
