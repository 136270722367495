import "./css/components-styles.css"

interface Props {
    onClick: () => void,
}

const CloseButton: React.FC<Props> = ({
    onClick,
}) => {
  return (
    <div
        className='close-button'
        onClick={onClick}
    >
      <div className="lh0">
        <img src="/images/symbols/x.svg"/>
      </div>
      <div className='standard-uppercase-text'>
        Chiudi
      </div>
    </div>
  )
}

export default CloseButton
