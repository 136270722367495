import CloseButton from "../../../components/CloseButton"
import "./css/NoTestAvailableDialog.css"

interface Props {
    onClick: () => void,
    patientName: string,
}

const NoTestAvailableDialog: React.FC<Props> = ({
    onClick,
    patientName,
}) => {
    return (
        <div className="no-test-available-container center-div">
            <div className="no-test-available-internal-container">
                <div className="no-test-available-button-row">
                    <CloseButton
                        onClick={onClick}
                    />
                </div>
                <div className="no-test-available-copy">
                    Il test di {patientName} non è ancora disponibile
                </div>
            </div>
        </div>
    )
}

export default NoTestAvailableDialog
