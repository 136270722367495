import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import CloseButton from "../../components/CloseButton"
import LoadingSpinner from "../../components/LoadingSpinner"
import { ACCOUNT_LOGO, APPOINTMENTS_LOGO, BASE_URL, CUSTOMERS_LOGO_FOCUS, HOME_LOGO, RELATIVE_PATH_PLATFORM, RELATIVE_PATH_PLATFORM_ACCOUNT, RELATIVE_PATH_PLATFORM_APPOINTMENTS, RELATIVE_PATH_PLATFORM_CUSTOMERS, RELATIVE_PATH_PLATFORM_HOME } from "../../utils/Constants"
import { KeyParameter } from "../../utils/Enums"
import KeyParametersDialog from "./components/KeyParametersDialog"
import NavBar from "./components/NavBar"
import { Patient } from "./components/Patient"
import "./css/CustomersSingleCustomerPageEditPlan.css"
import ExpertTipsWidget from "./widgets/ExpertTipsWidget"
import ImportantParametersWidget from "./widgets/ImportantParametersWidget"
import SetGoalWidget from "./widgets/SetGoalWidget"

const CustomersSingleCustomerPageEditPlan = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const userId = location.state.userId
    const planId = location.state.planId
    const idToken = location.state.idToken
    const patient: Patient = location.state.patient
    const email = location.state.email
    const [goal, setGoal] = useState(location.state.goal)
    const keyParameters = location.state.keyParameters
    const [expertKeyPoints, setExpertKeyPoints] = useState<string[]>(location.state.expertKeyPoints)
    const [expertKeyPointsString, setExpertKeyPointsString] = useState<string>(expertKeyPoints.join("\n"))
    const [expertComment, setExpertComment] = useState(location.state.expertComment)

    const [isLoading, setIsLoading] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_HOME, { state: { userId, idToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM, { state: { userId, idToken } })
    }
    const onCustomersIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_CUSTOMERS, { state: { userId, idToken } })
    }
    const onApointmentsIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_APPOINTMENTS, { state: { userId, idToken, email } })
    }
    const onAccountIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_ACCOUNT, { state: { userId, idToken } })
    }

    const onEditGoalClick = () => {
        setShowGoalDropdown(!showGoalDropdown)
    }

    const [showGoalDropdown, setShowGoalDropdown] = useState(false)

    const onDropdownChoice = (choice: string) => {
        setShowGoalDropdown(false)
        setGoal(choice)
    }

    const [showKeyParametersDialog, setShowKeyParametersDialog] = useState(false)

    const onEditKeyParametersClick = () => {
        setShowKeyParametersDialog(true)
    }

    const onAddKeyParameterClick = () => {
        setShowKeyParametersDialog(true)
    }

    const [keyParametersSelected, setKeyParametersSelected] = useState<KeyParameter[]>(keyParameters);
    const [realKeyParametersSelected, setRealKeyParametersSelected] = useState<KeyParameter[]>(keyParameters);

    const onKeyParameterCardClick = (keyParameter: KeyParameter) => {
        const index = keyParametersSelected.indexOf(keyParameter);
        if (index > -1) {
            setKeyParametersSelected([...keyParametersSelected.slice(0, index), ...keyParametersSelected.slice(index + 1)]);
        } else {
            setKeyParametersSelected([...keyParametersSelected, ...[keyParameter]]);
        }
    }

    const onExpertKeyParametersChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setExpertKeyPointsString(e.target.value)
        setExpertKeyPoints(expertKeyPointsString.split("\n"))
    }

    const onExpertCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setExpertComment(e.target.value)
    }

    const onConfirmClick = () => {
        console.log(goal)
        console.log(realKeyParametersSelected)
        console.log(expertKeyPoints)
        console.log(expertComment)
        callUpdatePlanApi()
    }

    async function callUpdatePlanApi() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/plans/${planId}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    data: {
                        comment: expertComment,
                        key_points: realKeyParametersSelected,
                        goal: goal,
                        important_key_points: expertKeyPoints,
                    }
                })
        })
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
            navigate("/platform-experts/customers/single-patient-page", { state: { patient, userId, idToken, email } })
        } else {
            const responseInfoJson = await response.json()
            console.log(responseInfoJson)
        }
    }

    return (
        <div className='App Platform relative'>
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                customersLogo={CUSTOMERS_LOGO_FOCUS}
                onCustomersIconClick={onCustomersIconClick}
                apointmentsLogo={APPOINTMENTS_LOGO}
                onApointmentsIconClick={onApointmentsIconClick}
                accountLogo={ACCOUNT_LOGO}
                onAccountIconClick={onAccountIconClick}
                isLoading={isLoading}
            />
            <div className={`platform-experts-no-navbar-container relative ${(isLoading) ? "visible-0-percent inactive" : ""}`}>
                <div className={`platform-experts-customers-single-patient-edit-plan-container ${showKeyParametersDialog ? "visible-30-percent inactive" : ""}`}>
                    <div className="platform-experts-customers-single-patient-edit-plan-title-row">
                        <div className="platform-experts-customers-single-patient-edit-plan-left-element">
                            <div className="platform-experts-customers-single-patient-edit-plan-name">{patient.name} {patient.familyName}</div>
                            <div className="platform-experts-customers-single-patient-edit-plan-heading">Modifica piano alimentare attuale</div>
                        </div>
                        <CloseButton
                            onClick={() => navigate("/platform-experts/customers/single-patient-page", { state: { patient, userId, idToken, email } })}
                        />
                    </div>
                    <div className="platform-experts-customers-single-patient-edit-plan-content">
                        <div className="platform-experts-customers-single-patient-edit-plan-content-container">
                            <div className="height-35" />
                            <SetGoalWidget
                                goal={goal}
                                onEditGoalClick={onEditGoalClick}
                                showGoalDropdown={showGoalDropdown}
                                onDropdownChoice={onDropdownChoice}
                            />
                            <div className="height-35" />
                            <ImportantParametersWidget
                                onEditKeyParametersClick={onEditKeyParametersClick}
                                onAddKeyParameterClick={onAddKeyParameterClick}
                                selectedKeyParameters={realKeyParametersSelected}
                                emptyBoxNumber={6 - realKeyParametersSelected.length}
                            />
                            <div className="height-35" />
                            <ExpertTipsWidget
                                expertTips={expertKeyPointsString}
                                expertComment={expertComment}
                                onExpertKeyParametersChange={onExpertKeyParametersChange}
                                onExpertCommentChange={onExpertCommentChange}
                            />
                        </div>
                    </div>
                    <div className="platform-experts-customers-single-patient-edit-plan-buttons-row">
                        <button
                            className="platform-experts-customers-single-patient-edit-plan-undo-button standard-uppercase-text"
                            onClick={() => navigate("/platform-experts/customers/single-patient-page", { state: { patient, userId, idToken, email } })}
                        >
                            Annulla
                        </button>
                        <button
                            className={`platform-experts-customers-single-patient-edit-plan-confirm-button primary-action-color-background standard-uppercase-text pointer`}
                            onClick={onConfirmClick}
                        >
                            Conferma modifiche
                        </button>
                    </div>
                </div>
                {
                    showKeyParametersDialog ?
                        <KeyParametersDialog
                            onCloseButtonClick={() => setShowKeyParametersDialog(false)}
                            isConfirmButtonDisabled={keyParametersSelected.length === 0}
                            keyParametersSelected={keyParametersSelected}
                            onKeyParameterClick={onKeyParameterCardClick}
                            onUndoClick={() => setKeyParametersSelected([])}
                            onConfirmClick={() => (
                                setShowKeyParametersDialog(false),
                                setRealKeyParametersSelected(keyParametersSelected))
                            }
                        /> : null
                }
            </div>
            {
                isLoading ? <div className="width-100-percent height-100-percent center-div absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default CustomersSingleCustomerPageEditPlan
