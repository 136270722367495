import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import SingleLineDivider from "../../components/SingleLineDivider"
import { ACCOUNT_LOGO, APPOINTMENTS_LOGO, BASE_URL, CUSTOMERS_LOGO, HOME_LOGO_FOCUS, RELATIVE_PATH_PLATFORM, RELATIVE_PATH_PLATFORM_ACCOUNT, RELATIVE_PATH_PLATFORM_APPOINTMENTS, RELATIVE_PATH_PLATFORM_CUSTOMERS, RELATIVE_PATH_PLATFORM_HOME } from "../../utils/Constants"
import { handleDaysDifference, handleHoursDifference, handleMinutesDifference } from "../../utils/Functions"
import { Appointment } from "./components/Appointment"
import NavBar from "./components/NavBar"
import "./css/Dashboard.css"
import "./css/Home.css"
import NextApointmentWidget from "./widgets/NextApointmentWidget"
import OldAppointmentWidget from "./widgets/OldAppointmentWidget"

const Home = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const userId = location.state.userId
  const idToken = location.state.idToken

  const [isLoading, setIsLoading] = useState(false)

  // Actions to perform when clicking on Navbar logos and Holifya icon
  const onHolifyaLogoClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_HOME, { state: { userId, idToken } })
  }
  const onHomeIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM, { state: { userId, idToken } })
  }
  const onCustomersIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_CUSTOMERS, { state: { userId, idToken } })
  }
  const onApointmentsIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_APPOINTMENTS, { state: { userId, idToken, email } })
  }
  const onAccountIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_ACCOUNT, { state: { userId, idToken } })
  }

  const requestOptionsGET = {
    method: 'GET',
    headers: {
      'Authorization': idToken!
    }
  }

  const [email, setEmail] = useState("")

  const [appointments, setAppointments] = useState<Appointment[]>([])

  // GET all appointments by organizer email
  async function callGetAppointmentsByOrganizerMailApi(email: string) {
    const responseUserinfo = await fetch(`${BASE_URL}/api/booking/appointments/organizer/${email}`, requestOptionsGET)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      const appointmentsArray: Appointment[] = []
      for (const appointment of responseJson) {
        appointmentsArray.push({
          date: appointment.payload.scheduled_event.start_time,
          patientName: appointment.payload.name,
          patientFamilyName: "",
          editUrl: appointment.payload.reschedule_url,
          connectUrl: appointment.payload.scheduled_event.location.join_url,
          remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
          remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
          remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
          status: appointment.payload.status,
        })
      }
      setAppointments(appointmentsArray)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  // GET all patients by user_id
  async function callGetMedicalUserApi() {
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/${userId}`, requestOptionsGET)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      setEmail(responseJson.email)
      callGetAppointmentsByOrganizerMailApi(responseJson.email)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  useEffect(() => {
    callGetMedicalUserApi()
  }, [])

  return (
    <div className='App Platform relative'>
      <NavBar
        onHolifyaLogoClick={onHolifyaLogoClick}
        homeLogo={HOME_LOGO_FOCUS}
        onHomeIconClick={onHomeIconClick}
        customersLogo={CUSTOMERS_LOGO}
        onCustomersIconClick={onCustomersIconClick}
        apointmentsLogo={APPOINTMENTS_LOGO}
        onApointmentsIconClick={onApointmentsIconClick}
        accountLogo={ACCOUNT_LOGO}
        onAccountIconClick={onAccountIconClick}
        isLoading={isLoading}
      />
      <div className="platform-experts-no-navbar-container">
        <div className="platform-experts-dashboard-container">
          <div className="platform-experts-dashboard-title-row">
            <div className="platform-experts-dashboard-title-container">
              <div className="platform-experts-dashboard-title-logo-container">
                <img src="/images/platformLogos/home-big-logo.svg" />
              </div>
              <div className="platform-experts-dashboard-title">
                Home
              </div>
            </div>
          </div>
          <div className="platform-experts-dashboard-content-container">
            <div className="platform-experts-final-element-container">
              <div className="height-20" />
              <div className="platform-home-heading">
                Appuntamenti di oggi
              </div>
              <div className="height-20" />
              {
                appointments.map(appointment => (
                  appointment.remainingDays === 0 ?
                    appointment.remainingMinutes > -30 ?
                      <div key={appointments.indexOf(appointment)}>
                        <NextApointmentWidget
                          appointment={appointment}
                          isUrgent={true}
                        />
                        <div className="height-20" />
                      </div> :
                      <div key={appointments.indexOf(appointment)}>
                        <OldAppointmentWidget
                          appointment={appointment}
                        />
                        <div className="height-20" />
                      </div>
                    : null
                ))
              }
              <SingleLineDivider />
              <div className="height-20" />
              <div className="platform-expert-content-text">
                Qui potrai visualizzare tutti gli aggiornamenti recenti dei tuoi clienti
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
