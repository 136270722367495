import { FormEvent, useState } from "react"
import { useNavigate } from "react-router-dom"
import BackButton from "../../components/BackButton"
import LoadingSpinner from "../../components/LoadingSpinner"
import { BASE_URL, HIDE_PASSWORD_ICON, HIDE_PASSWORD_ICON_DISABLED, SHOW_PASSWORD_ICON, SHOW_PASSWORD_ICON_DISABLED } from "../../utils/Constants"
import { ExpertType, InputType } from "../../utils/Enums"
import "./css/LoginPage.css"

const LoginPage = () => {

    const navigate = useNavigate()

    // States for changing email and password inputs
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [passwordInputType, setPasswordInputType] = useState(InputType.Password)
    const [showPasswordIcon, setShowPasswordIcon] = useState(SHOW_PASSWORD_ICON_DISABLED)

    const [isLoading, setIsLoading] = useState(false)

    const onShowPasswordIconClick = () => {
        switch (showPasswordIcon) {
            case "/images/symbols/show-password-disabled.svg":
                setShowPasswordIcon(HIDE_PASSWORD_ICON_DISABLED)
                setPasswordInputType(InputType.Text)
                break;
            case "/images/symbols/hide-password-disabled.svg":
                setShowPasswordIcon(SHOW_PASSWORD_ICON_DISABLED)
                setPasswordInputType(InputType.Password)
                break;
            case "/images/symbols/show-password.svg":
                setShowPasswordIcon(HIDE_PASSWORD_ICON)
                setPasswordInputType(InputType.Text)
                break;
            case "/images/symbols/hide-password.svg":
                setShowPasswordIcon(SHOW_PASSWORD_ICON)
                setPasswordInputType(InputType.Password)
                break;
            default:
                break;
        }
    }

    const submitButtonDisabled = email === "" || password === ""

    const onPasswordInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const nextPassword = e.target.value
        setPassword(e.target.value)

        if(password === "" && nextPassword !== "") {
            if (showPasswordIcon === SHOW_PASSWORD_ICON_DISABLED) {
                setShowPasswordIcon(SHOW_PASSWORD_ICON)
            } else if (showPasswordIcon === HIDE_PASSWORD_ICON_DISABLED) {
                setShowPasswordIcon(HIDE_PASSWORD_ICON)
            }
        } else if (password !== "" && nextPassword === "") {
            if (showPasswordIcon === SHOW_PASSWORD_ICON) {
                setShowPasswordIcon(SHOW_PASSWORD_ICON_DISABLED)
            } else if (showPasswordIcon === HIDE_PASSWORD_ICON) {
                setShowPasswordIcon(HIDE_PASSWORD_ICON_DISABLED)
            }
        }
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        callLoginAPI()
    }

    // LOGIN Api
    async function callLoginAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    password: password,
                    role: ExpertType.Nutritionist,
                })
        })
        setIsLoading(false)
        if (!response.ok) {
        } else {
            // Se la chimata va a buon fine si va alla login success page
            const result = await response.json();
            const idToken = result.id_token
            const accessToken = result.access_token
            const userId = result.id
            const refreshToken = result.refresh_token
            const role = result.role
            navigate(`/login-success`, { state: { idToken, accessToken, userId, refreshToken, role } })
        }
    }

    return (
        <div className="App relative">
            <div className={`login-page-buttons-row ${isLoading ? "visible-30-percent inactive" : ""}`}>
                <div className="login-page-buttons-container">
                    <BackButton
                        onClick={() => navigate("/")}
                    />
                    <button
                        className="login-page-create-account-button secondary-action-color-background standard-uppercase-text"
                        onClick={() => navigate("/email-registration", { state: { newsletterAccepted: false } })}
                    >
                        Crea Account
                    </button>
                </div>
            </div>
            <div className={`login-page-content ${isLoading ? "visible-30-percent inactive" : ""}`}>
                <form
                    className="login-page-login-form"
                    onSubmit={handleSubmit}
                >
                    <div className="login-page-login-form-internal-container">
                        <div>
                            <img src="/images/holifyaLogos/holifya-logo-blue.svg" />
                        </div>
                        <div className="login-page-login-copy">
                            Inserisci le tue credenziali per accedere al mondo holifya
                        </div>
                        <div className="login-page-input-container">
                            <label className="login-page-input-label">
                                Indirizzo e-mail
                            </label>
                            <div className="login-page-input-internal-container">
                                <input
                                    className="login-page-input-field s16 w400"
                                    type="text"
                                    placeholder="nome@esempio.com"
                                    onChange={
                                        (e) => setEmail(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="login-page-input-container">
                            <label className="login-page-input-label">
                                Password
                            </label>
                            <div className="login-page-input-internal-container">
                                <input
                                    className="login-page-input-field s16 w400"
                                    type={passwordInputType}
                                    placeholder="Password"
                                    onChange={onPasswordInputChangeHandler}
                                />
                                <span
                                    className="login-page-show-password-container"
                                    onClick={onShowPasswordIconClick}
                                >
                                    <img
                                        src={showPasswordIcon}
                                    />
                                </span>
                            </div>
                        </div>
                        <button
                            className={`login-page-confirm-button primary-action-color-background ${submitButtonDisabled ? "" : "pointer"}`}
                            disabled={submitButtonDisabled}
                        >
                            Accedi
                        </button>
                        {/* <Divider
                            text="o"
                        />
                        <button className="create-account-button relative">
                            <img
                                className="absolute left-10"
                                src="/images/logos/google-logo.svg"
                            />
                            Registrati con Google
                        </button>
                        <button className="create-account-button relative">
                            <img
                                className="absolute left-10"
                                src="/images/logos/apple-logo.svg"
                            />
                            Registrati con Apple
                        </button> */}
                    </div>
                </form>
                <button
                    className="login-page-cant-login-button"
                    onClick={() => { navigate("/forgot-password") }}
                >
                    Non riesci ad accedere?
                </button>
            </div>
            <div className="login-page-buttons-row" />
            {
                isLoading ? <div className="width-100-percent height-100-percent center-div absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default LoginPage
