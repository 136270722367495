import { Plan } from "../components/Plan"
import DropBox from "./components/DropBox"
import PastPlanBox from "./components/PastPlanBox"
import "./css/FileSectionWidget.css"

interface Props {
    onOpenAnswersClick: () => void,
    onOpenExamsClick: () => void,
    onOpenDNATestClick: () => void,
    oldPlans: Plan[],
}

const FileSectionWidget: React.FC<Props> = ({
    onOpenAnswersClick,
    onOpenExamsClick,
    onOpenDNATestClick,
    oldPlans,
}) => {

    const handlePastPlansExternalContainer = () => {
        switch (oldPlans.length) {
            case 1:
                return "file-section-widget-past-plans-container-height-1"
            case 2:
                return "file-section-widget-past-plans-container-height-2"
            case 3:
                return "file-section-widget-past-plans-container-height-3"
            case 4:
                return "file-section-widget-past-plans-container-height-4"
            case 5:
                return "file-section-widget-past-plans-container-height-5"
            case 6:
                return "file-section-widget-past-plans-container-height-6"
            default:
                return ""
        }
    }

    const handlePastPlansInternalContainer = () => {
        switch (oldPlans.length) {
            case 1:
                return "file-section-widget-past-plans-internal-container-height-1"
            case 2:
                return "file-section-widget-past-plans-internal-container-height-2"
            case 3:
                return "file-section-widget-past-plans-internal-container-height-3"
            case 4:
                return "file-section-widget-past-plans-internal-container-height-4"
            case 5:
                return "file-section-widget-past-plans-internal-container-height-5"
            case 6:
                return "file-section-widget-past-plans-internal-container-height-6"
            default:
                return ""
        }
    }

    return (
        <div className="file-section-widget-container">
            <div className="file-section-widget-files-container">
                <DropBox
                    title="Le risposte"
                    date=""
                    onButtonClick={onOpenAnswersClick}
                />
                <DropBox
                    title="Esami"
                    date=""
                    onButtonClick={onOpenExamsClick}
                />
                <DropBox
                    title="Test del DNA"
                    date=""
                    onButtonClick={onOpenDNATestClick}
                />
                <button
                    className="file-section-widget-share-file-button primary-action-color-background"
                    disabled={true}
                >
                    Condividi documento
                </button>
            </div>
            {
                oldPlans.length !== 0 ?
                    <div className="file-section-widget-past-plans-full-container">
                        <div className="file-section-widget-past-plans-heading">
                            Piani alimentari precedenti
                        </div>
                        <div className="height-20" />
                        <div className={`file-section-widget-past-plans-container ${handlePastPlansExternalContainer()}`}>
                            <div className={`file-section-widget-past-plans-internal-container ${handlePastPlansInternalContainer()}`}>
                                {
                                    oldPlans.map(plan => (
                                        <PastPlanBox
                                            key={oldPlans.indexOf(plan)}
                                            plan={plan}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

export default FileSectionWidget
