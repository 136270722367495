import DownloadButton from "../../../../components/DownloadButton"
import { handleDate } from "../../../../utils/Functions"
import { Plan } from "../../components/Plan"
import "./css/PastPlanBox.css"

interface Props {
    plan: Plan,
}

const PastPlanBox: React.FC<Props> = ({
    plan,
}) => {
    return (
        <div className="past-plan-box-external-container">
            <div className="past-plan-box-internal-container">
                <div className="past-plan-box-left-element">
                    <div className="past-plan-box-plan-date">
                        {
                            handleDate(plan.spanDate)
                        }
                    </div>
                    <div className="past-plan-box-plan-name">
                        {
                            plan.name
                        }
                    </div>
                </div>
                <DownloadButton
                    url={plan.url}
                />
            </div>
        </div>
    )
}

export default PastPlanBox
