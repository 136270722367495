import "./css/components-styles.css"

interface Props {
  url: string,
}

const DownloadButton: React.FC<Props> = ({
  url,
}) => {
  return (
    <div
      className="download-button"
      onClick={() => window.open(url, "_blank")}
    >
        <div>
            <img src="/images/symbols/download-icon.svg"/>
        </div>
        <div className="standard-uppercase-text">Scarica</div>
    </div>
  )
}

export default DownloadButton
