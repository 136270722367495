import { handleDayOfMonth, handleHourAndMinutes, handleMonth } from "../../../utils/Functions"
import { Appointment } from "../components/Appointment"
import "./css/NextApointmentWidget.css"

interface Props {
  isUrgent: boolean,
  appointment: Appointment,
}

const NextApointmentWidget: React.FC<Props> = ({
  isUrgent,
  appointment,
}) => {

  const handleMissingTime = () => {
    if (appointment.remainingDays > 1) {
      return `Mancano ${appointment.remainingDays} giorni`
    } else if (appointment.remainingDays === 1) {
      return "Manca 1 giorno"
    } else {
      if (appointment.remainingHours > 1) {
        return `Mancano ${appointment.remainingHours} ore`
      } else if (appointment.remainingHours === 1 && appointment.remainingMinutes >= 60) {
        return "Manca 1 ora"
      }
      else {
        if (appointment.remainingMinutes > 1) {
          return `Mancano ${appointment.remainingMinutes} minuti`
        } else if (appointment.remainingMinutes === 1) {
          return `Manca 1 minuto`
        } else {
          return `Il meeting è iniziato, connettiti al più presto`
        }
      }
    }
  }

  return (
    <div className={`${isUrgent ? "next-apointment-widget-container-urgent" : "next-apointment-widget-container"}`}>
      <div className="next-apointment-widget-internal-container">
        <div className="next-apointment-widget-upper-element-container">
          <div className="next-apointment-widget-upper-element-heading">
            Videoconsulto | <span className={`${isUrgent ? "next-apointment-widget-red-text" : ""}`}>{handleMissingTime()}</span>
          </div>
          <div className="next-apointment-widget-upper-element-date">
            <span>{handleDayOfMonth(appointment.date)} {handleMonth(appointment.date)} <span className="next-apointment-widget-regular-font">{handleHourAndMinutes(appointment.date)}</span></span>
          </div>
        </div>
        <div className="next-apointment-widget-bottom-element-container">
          <div className="next-apointment-widget-bottom-element-name">
            {appointment.patientName} {appointment.patientFamilyName}
          </div>
          <div className="next-apointment-widget-bottom-element-buttons-container">
            <button
              className={`next-apointment-widget-bottom-element-edit-button ternary-action-color-background ${isUrgent ? "" : "pointer"}`}
              onClick={() => window.open(appointment.editUrl)}
              disabled={isUrgent}
            >
              Modifica
            </button>
            <button
              className="next-apointment-widget-bottom-element-connect-button primary-action-color-background"
              onClick={() => window.open(appointment.connectUrl)}
            >
              Collegati
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NextApointmentWidget
