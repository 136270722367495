import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import LoadingSpinner from "../../components/LoadingSpinner"
import { ACCOUNT_LOGO, APPOINTMENTS_LOGO_FOCUS, BASE_URL, CUSTOMERS_LOGO, HOME_LOGO, RELATIVE_PATH_PLATFORM, RELATIVE_PATH_PLATFORM_ACCOUNT, RELATIVE_PATH_PLATFORM_APPOINTMENTS, RELATIVE_PATH_PLATFORM_CUSTOMERS, RELATIVE_PATH_PLATFORM_HOME } from "../../utils/Constants"
import { handleDaysDifference, handleHoursDifference, handleMinutesDifference } from "../../utils/Functions"
import { Appointment } from "./components/Appointment"
import NavBar from "./components/NavBar"
import "./css/Apointments.css"
import NextApointmentWidget from "./widgets/NextApointmentWidget"
import OldAppointmentWidget from "./widgets/OldAppointmentWidget"

const Apointments = () => {

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const userId = location.state.userId
  const idToken = location.state.idToken
  const email = location.state.email

  // Actions to perform when clicking on Navbar logos and Holifya icon
  const onHolifyaLogoClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_HOME, { state: { userId, idToken } })
  }
  const onHomeIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM, { state: { userId, idToken } })
  }
  const onCustomersIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_CUSTOMERS, { state: { userId, idToken } })
  }
  const onApointmentsIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_APPOINTMENTS, { state: { userId, idToken } })
  }
  const onAccountIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_ACCOUNT, { state: { userId, idToken } })
  }

  useEffect(() => {
    callGetAppointmentsByOrganizerMailApi()
  }, [])

  const requestOptionsGET = {
    method: 'GET',
    headers: {
      'Authorization': idToken!
    }
  }

  const [appointments, setAppointments] = useState<Appointment[]>([])

  // GET all appointments by organizer email
  async function callGetAppointmentsByOrganizerMailApi() {
    setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/booking/appointments/organizer/${email}`, requestOptionsGET)
    setIsLoading(false)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      const appointmentsArray: Appointment[] = []
      for (const appointment of responseJson) {
        appointmentsArray.push({
          date: appointment.payload.scheduled_event.start_time,
          patientName: appointment.payload.name,
          patientFamilyName: "",
          editUrl: appointment.payload.reschedule_url,
          connectUrl: appointment.payload.scheduled_event.location.join_url,
          remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
          remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
          remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
          status: appointment.payload.status,
        })
      }
      setAppointments(appointmentsArray)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  return (
    <div className='App Platform relative'>
      <NavBar
        onHolifyaLogoClick={onHolifyaLogoClick}
        homeLogo={HOME_LOGO}
        onHomeIconClick={onHomeIconClick}
        customersLogo={CUSTOMERS_LOGO}
        onCustomersIconClick={onCustomersIconClick}
        apointmentsLogo={APPOINTMENTS_LOGO_FOCUS}
        onApointmentsIconClick={onApointmentsIconClick}
        accountLogo={ACCOUNT_LOGO}
        onAccountIconClick={onAccountIconClick}
        isLoading={isLoading}
      />
      <div className={`platform-experts-no-navbar-container ${isLoading ? "visible-0-percent inactive" : ""}`}>
        <div className="platform-experts-dashboard-container">
          <div className="platform-experts-dashboard-title-row">
            <div className="platform-experts-dashboard-title-container">
              <div className="platform-experts-dashboard-title-logo-container">
                <img src="/images/platformLogos/apointments-big-logo.svg" />
              </div>
              <div className="platform-experts-dashboard-title">
                Appuntamenti
              </div>
            </div>
          </div>
          <div className="platform-experts-dashboard-content-container">
            <div className="platform-experts-final-element-container">
              <div>
                <div className="platform-experts-apointments-heading">
                  Oggi in programma
                </div>
                <div className="height-20" />
                {
                  appointments.map(appointment => (
                    appointment.remainingDays === 0 ?
                      appointment.remainingMinutes > -30 ?
                        <div key={appointments.indexOf(appointment)}>
                          <div className="height-20" />
                          <NextApointmentWidget
                            isUrgent={true}
                            appointment={appointment} />
                          <div className="height-20" />
                        </div> :
                        <div key={appointments.indexOf(appointment)}>
                          <OldAppointmentWidget
                            appointment={appointment}
                          />
                          <div className="height-20" />
                        </div>
                      : null
                  ))
                }
              </div>
              <div>
                <div className="platform-experts-apointments-heading">
                  In programma in futuro
                </div>
                {
                  appointments.map(appointment => (
                    appointment.remainingDays >= 1 ?
                      <div key={appointments.indexOf(appointment)}>
                        <div className="height-20" />
                        <NextApointmentWidget
                          isUrgent={appointment.remainingDays === 1}
                          appointment={appointment} />
                        <div className="height-20" />
                      </div>
                      : null
                  ))
                }
              </div>
              <div className="height-20" />
            </div>
          </div>
        </div>
      </div>
      {
        isLoading ? <div className="width-100-percent height-100-percent center-div absolute"><LoadingSpinner /></div> : null
      }
    </div>
  )
}

export default Apointments
