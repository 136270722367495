import "./css/ExpertTipsWidget.css"

interface Props {
    onExpertKeyParametersChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    onExpertCommentChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    expertTips?: string,
    expertComment?: string[],
}

const ExpertTipsWidget: React.FC<Props> = ({
    onExpertKeyParametersChange,
    onExpertCommentChange,
    expertTips,
    expertComment,
}) => {
    return (
        <div className="expert-tips-widget-full-container">
            <div className="expert-tips-widget-left-element-container">
                <div className="expert-tips-widget-title-row">
                    <div className="expert-tips-widget-title">
                        Cose importanti da tenere a mente
                    </div>
                </div>
                <div className="height-20" />
                <div className="expert-tips-widget-tips-container">
                    <textarea
                        value={expertTips}
                        className="expert-tips-widget-tips-internal-container"
                        onChange={onExpertKeyParametersChange}
                    />
                </div>
            </div>
            <div className="expert-tips-widget-right-element-container">
                <div className="expert-tips-widget-title-row">
                    <div className="expert-tips-widget-title">
                        Il commento del piano
                    </div>
                </div>
                <div className="height-20" />
                <div className="expert-tips-widget-tips-container">
                    <textarea
                        value={expertComment}
                        className="expert-tips-widget-tips-internal-container"
                        onChange={onExpertCommentChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default ExpertTipsWidget
