import { useNavigate } from "react-router-dom"
import SingleLineDivider from "../../../../components/SingleLineDivider"
import { QuizType } from "../../../../utils/Enums"
import { handleQuizName } from "../../../../utils/Functions"
import { Survey } from "../../components/Survey"
import "./css/SurveyCard.css"

interface Props {
    survey: Survey,
    onCardClick: () => void,
}

const SurveyCard: React.FC<Props> = ({
    survey,
    onCardClick,
}) => {

    const handleQuizContents = () => {
        switch (survey.type) {
            case QuizType.QuizQ:
                return <div>
                    <div className="survey-card-quiz-contents-heading">Cosa contiene:</div>
                    <div className="survey-card-quiz-contents-content">
                        Questo è il primo questionario a cui abbiamo sottoposto il paziente. Domande ampie su obiettivi e lifestyle per inquadrare il suo primo passo in Holifya.
                    </div>
                </div>
            case QuizType.SurveyA:
                return <div>
                    <div className="survey-card-quiz-contents-heading">Cosa contiene:</div>
                    <div className="survey-card-quiz-contents-content">
                        Questo è il secondo questionario a cui abbiamo sottoposto il paziente. Domande sulle sue informazioni anamnestiche.
                    </div>
                </div>
            case QuizType.SurveyB:
                return <div>
                    <div className="survey-card-quiz-contents-heading">Cosa contiene:</div>
                    <div className="survey-card-quiz-contents-content">
                        Questo è il terzo questionario a cui abbiamo sottoposto il paziente, disponibile solo per chi acquista un piano nutrizionale. Le sue abitudini di vita / sport / alimentazione.
                    </div>
                </div>
        }
    }

    return (
        <div
            className="survey-card-container"
            onClick={onCardClick}
        >
            <div className="survey-card-internal-container">
                <div className="survey-card-upper-element-container">
                    <div className="survey-card-title">
                        {handleQuizName(survey.type)}
                    </div>
                    <div className="survey-card-date">
                        {survey.createdAt}
                    </div>
                </div>
                <SingleLineDivider />
                <div className="survey-card-bottom-element-container">
                    {handleQuizContents()}
                </div>
            </div>
        </div>
    )
}

export default SurveyCard
