import { useLocation, useNavigate } from "react-router-dom"
import BackButton from "../../components/BackButton"
import { useState } from "react"
import "./css/EmailRegistrationCode.css"
import { BASE_URL } from "../../utils/Constants"
import { ExpertType } from "../../utils/Enums"

const EmailRegistrationCode = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const email = location.state.email
    const role = location.state.role
    const newsletterAccepted = location.state.newsletterAccepted
    const name = location.state.name
    const familyName = location.state.familyName
    const password = location.state.password

    const [isLoading, setIsLoading] = useState(false)

    const [confirmationCode, setConfirmationCode] = useState("")

    const onConfirmCodeClick = () => {
        callConfirmCodeAPI()
    }

    async function callConfirmCodeAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/confirm`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    code: confirmationCode,
                    role: role,
                }
            )
        })
        setIsLoading(false)
        if (!response.ok) {
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
            callLoginAPI()
        }
    }

    // LOGIN Api
    async function callLoginAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    password: password,
                    role: ExpertType.Nutritionist,
                })
        })
        setIsLoading(false)
        if (!response.ok) {
        } else {
            // Se la chimata va a buon fine si va alla login success page
            const result = await response.json();
            const idToken = result.id_token
            const accessToken = result.access_token
            const userId = result.id
            const refreshToken = result.refresh_token
            const role = result.role
            console.log(role)
            navigate("/email-registration-complete-account", { state: { name, familyName, idToken, accessToken, userId, refreshToken } })
        }
    }

    const confirmButonDisabled = confirmationCode.length < 6

    return (
        <div className="App">
            <div className={`email-registration-code-page-buttons-row ${isLoading ? "visible-30-percent inactive" : ""}`}>
                <div className="email-registration-code-page-buttons-container">
                    <BackButton
                        onClick={() => navigate("/email-registration", { state: { newsletterAccepted } })}
                    />
                </div>
            </div>
            <div className="email-registration-code-page-content">
                <div className="email-registration-code-page-external-container">
                    <div className="email-registration-code-page-internal-container">
                        <div className="email-registration-code-heading">Inserisci codice</div>
                        <div className="email-registration-code-copy">
                            Hai ricevuto per e-mail un codice a 6 cifre per verificare il tuo indirizzo e-mail. Inseriscilo qui sotto.
                        </div>
                        <div className="email-registration-code-page-input-container">
                            <label className="email-registration-code-page-input-label">
                                Codice di verifica
                            </label>
                            <div className="email-registration-code-page-input-internal-container">
                                <input
                                    className="email-registration-code-page-input-field s16 w400"
                                    type="text"
                                    placeholder="00000"
                                    onChange={
                                        (e) => setConfirmationCode(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <button
                            className={`email-registration-code-page-confirm-button primary-action-color-background standard-uppercase-text ${confirmButonDisabled ? "" : "pointer"}`}
                            onClick={onConfirmCodeClick}
                            disabled={confirmButonDisabled}
                        >
                            Conferma codice
                        </button>
                        <button className="email-registration-code-page-send-again-button standard-uppercase-text">
                            Invia nuovo codice
                        </button>
                    </div>
                </div>
            </div>
            <div className={`email-registration-code-page-buttons-row`} />
        </div>
    )
}

export default EmailRegistrationCode
