import axios from "axios"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import CloseButton from "../../components/CloseButton"
import LoadingSpinner from "../../components/LoadingSpinner"
import { ACCOUNT_LOGO, APPOINTMENTS_LOGO, BASE_URL, CUSTOMERS_LOGO_FOCUS, HOME_LOGO, RELATIVE_PATH_PLATFORM, RELATIVE_PATH_PLATFORM_ACCOUNT, RELATIVE_PATH_PLATFORM_APPOINTMENTS, RELATIVE_PATH_PLATFORM_CUSTOMERS, RELATIVE_PATH_PLATFORM_HOME } from "../../utils/Constants"
import { KeyParameter } from "../../utils/Enums"
import KeyParametersDialog from "./components/KeyParametersDialog"
import NavBar from "./components/NavBar"
import { Patient } from "./components/Patient"
import "./css/CustomersSingleCustomerPageCreatePlan.css"
import ExpertTipsWidget from "./widgets/ExpertTipsWidget"
import ImportantParametersWidget from "./widgets/ImportantParametersWidget"
import SetGoalWidget from "./widgets/SetGoalWidget"
import UploadPlanWidget from "./widgets/UploadPlanWidget"

const CustomersSingleCustomerPageCreatePlan = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const userId = location.state.userId
    const idToken = location.state.idToken
    const patient: Patient = location.state.patient
    const email = location.state.email

    const [isLoading, setIsLoading] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_HOME, { state: { userId, idToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM, { state: { userId, idToken } })
    }
    const onCustomersIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_CUSTOMERS, { state: { userId, idToken } })
    }
    const onApointmentsIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_APPOINTMENTS, { state: { userId, idToken, email } })
    }
    const onAccountIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_ACCOUNT, { state: { userId, idToken } })
    }

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    const onEditGoalClick = () => {
        setShowGoalDropdown(!showGoalDropdown)
    }

    const onEditKeyParametersClick = () => {
        setShowKeyParametersDialog(true)
    }

    const onAddKeyParameterClick = () => {
        setShowKeyParametersDialog(true)
    }

    useEffect(() => {
    }, [])

    const [showKeyParametersDialog, setShowKeyParametersDialog] = useState(false)
    const [showGoalDropdown, setShowGoalDropdown] = useState(false)

    const [goal, setGoal] = useState("")

    const [keyParametersSelected, setKeyParametersSelected] = useState<KeyParameter[]>([]);
    const [realKeyParametersSelected, setRealKeyParametersSelected] = useState<KeyParameter[]>([]);

    const [expertComment, setExpertComment] = useState("")
    const onExpertCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setExpertComment(e.target.value)
        if (isPublishButtonDisabled) {
        }
    }

    const [expertKeyParameters, setExpertKeyParameters] = useState<string[]>()
    const [expertKeyParametersString, setExpertKeyParametersString] = useState("")
    const onExpertKeyParametersChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setExpertKeyParametersString(e.target.value)
        if (isPublishButtonDisabled) {
        }
        setExpertKeyParameters(expertKeyParametersString.split("\n"))
    }

    const onKeyParameterCardClick = (keyParameter: KeyParameter) => {
        const index = keyParametersSelected.indexOf(keyParameter);
        if (index > -1) {
            setKeyParametersSelected([...keyParametersSelected.slice(0, index), ...keyParametersSelected.slice(index + 1)]);
        } else {
            setKeyParametersSelected([...keyParametersSelected, ...[keyParameter]]);
        }
    }

    const [uploadedFile, setUploadedFile] = useState<File>()

    const isPublishButtonDisabled = goal === "" || realKeyParametersSelected.length === 0 || expertComment === "" || expertKeyParameters?.length === 0 || uploadedFile === undefined

    const isConfirmButtonDisabled = keyParametersSelected.length === 0

    const onDropdownChoice = (choice: string) => {
        setShowGoalDropdown(false)
        setGoal(choice)
    }

    const onUndoClick = () => {
        setKeyParametersSelected([])
    }

    const onConfirmClick = () => {
        setShowKeyParametersDialog(false)
        setRealKeyParametersSelected(keyParametersSelected)
    }

    const onPublishButtonClick = () => {
        callCreatePlanApi()
    }

    async function callCreatePlanApi() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/plans`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    user_id: patient.userId,
                    data: {
                        comment: expertComment,
                        key_points: realKeyParametersSelected,
                        goal: goal,
                        important_key_points: expertKeyParameters,
                    }
                }
            )
        })
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
            callGetUrlApi(responseJson.plan_id)
        }
    }

    // What happens when the file is uploaded
    const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true)
        const files = (e.target as HTMLInputElement).files
        setUploadedFile(files![0])
        setIsLoading(false)
    }

    // What happens when the file is removed
    const onFileInputRemove = () => {
        setIsLoading(true)
        setUploadedFile(undefined)
        setIsLoading(false)
    }

    // GET an url to call
    async function callGetUrlApi(planId: string) {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/plans/patient/${patient.userId}/plan-url/upload?filename=${planId}.pdf`, requestOptionsGET)
        if (response.ok) {
            const urlResponse = await response.json()
            const formData = new FormData()
            Object.entries(urlResponse.fields).forEach(([k, v]) => {
                formData.append(k, v as unknown as string)
            })
            formData.append('file', uploadedFile!)
            const s3response = await axios.post(urlResponse.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setIsLoading(false)
            if (s3response.status === 204) {
                console.log("File uploaded successfully")
                navigate("/platform-experts/customers/single-patient-page", { state: { patient, userId, idToken, email } })
            } else {
                console.log("File not uploaded")
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    return (
        <div className='App Platform relative'>
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                customersLogo={CUSTOMERS_LOGO_FOCUS}
                onCustomersIconClick={onCustomersIconClick}
                apointmentsLogo={APPOINTMENTS_LOGO}
                onApointmentsIconClick={onApointmentsIconClick}
                accountLogo={ACCOUNT_LOGO}
                onAccountIconClick={onAccountIconClick}
                isLoading={isLoading}
            />
            <div className={`platform-experts-no-navbar-container relative ${(isLoading) ? "visible-0-percent inactive" : ""}`}>
                <div className={`platform-experts-customers-single-patient-create-plan-container ${showKeyParametersDialog ? "visible-30-percent inactive" : ""}`}>
                    <div
                        className="platform-experts-customers-single-patient-create-plan-title-row"
                    >
                        <div className="platform-experts-customers-single-patient-create-plan-title-row-left-element">
                            <div className="platform-experts-customers-single-patient-create-plan-title-row-patient-name">
                                {patient.name} {patient.familyName}
                            </div>
                            <div className="platform-experts-customers-single-patient-create-plan-title-row-heading">
                                Crea nuovo piano
                            </div>
                        </div>
                        <CloseButton
                            onClick={() => navigate("/platform-experts/customers/single-patient-page", { state: { patient, userId, idToken, email } })}
                        />
                    </div>
                    <div className="platform-experts-customers-single-patient-create-plan-content">
                        <div className="platform-experts-customers-single-patient-create-plan-content-container">
                            <div className="height-20" />
                            <SetGoalWidget
                                goal={goal}
                                onEditGoalClick={onEditGoalClick}
                                showGoalDropdown={showGoalDropdown}
                                onDropdownChoice={onDropdownChoice}
                            />
                            <div className="height-35" />
                            <ImportantParametersWidget
                                onEditKeyParametersClick={onEditKeyParametersClick}
                                onAddKeyParameterClick={onAddKeyParameterClick}
                                selectedKeyParameters={realKeyParametersSelected}
                                emptyBoxNumber={6 - realKeyParametersSelected.length}
                            />
                            <div className="height-35" />
                            <ExpertTipsWidget
                                onExpertKeyParametersChange={onExpertKeyParametersChange}
                                onExpertCommentChange={onExpertCommentChange}
                            />
                            <div className="height-35" />
                            <UploadPlanWidget
                                isFileUploaded={uploadedFile !== undefined}
                                onFileInputChange={onFileInputChange}
                                onFileInputRemove={onFileInputRemove}
                            />
                        </div>
                    </div>
                    <div className="platform-experts-customers-single-patient-create-plan-buttons-row">
                        <button
                            className="platform-experts-customers-single-patient-create-plan-undo-button standard-uppercase-text"
                            onClick={() => navigate("/platform-experts/customers/single-patient-page", { state: { patient, userId, idToken, email } })}
                        >
                            Annulla
                        </button>
                        <button
                            className={`platform-experts-customers-single-patient-create-plan-publish-button primary-action-color-background standard-uppercase-text ${isPublishButtonDisabled ? "" : "pointer"}`}
                            disabled={isPublishButtonDisabled}
                            onClick={onPublishButtonClick}
                        >
                            Pubblica
                        </button>
                    </div>
                </div>
                {
                    showKeyParametersDialog ?
                        <KeyParametersDialog
                            onCloseButtonClick={() => setShowKeyParametersDialog(false)}
                            isConfirmButtonDisabled={isConfirmButtonDisabled}
                            keyParametersSelected={keyParametersSelected}
                            onKeyParameterClick={onKeyParameterCardClick}
                            onUndoClick={onUndoClick}
                            onConfirmClick={onConfirmClick}
                        /> : null
                }
            </div>
            {
                isLoading ? <div className="width-100-percent height-100-percent center-div absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default CustomersSingleCustomerPageCreatePlan
