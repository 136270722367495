import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import LoadingSpinner from "../../components/LoadingSpinner"
import SingleLineDivider from "../../components/SingleLineDivider"
import { ACCOUNT_LOGO, APPOINTMENTS_LOGO, BASE_URL, CUSTOMERS_LOGO_FOCUS, HOME_LOGO, RELATIVE_PATH_PLATFORM, RELATIVE_PATH_PLATFORM_ACCOUNT, RELATIVE_PATH_PLATFORM_APPOINTMENTS, RELATIVE_PATH_PLATFORM_CUSTOMERS, RELATIVE_PATH_PLATFORM_HOME } from "../../utils/Constants"
import { AppointmentStatus, ExamFolder, KeyParameter } from "../../utils/Enums"
import { handleDate, handleDaysDifference, handleEnergy, handleHoursDifference, handleMinutesDifference, handleSleep, handleStressLevel, handleTraining, handleWater } from "../../utils/Functions"
import { Appointment } from "./components/Appointment"
import NavBar from "./components/NavBar"
import NoTestAvailableDialog from "./components/NoTestAvailableDialog"
import { Patient } from "./components/Patient"
import { Plan } from "./components/Plan"
import "./css/CustomersSingleCustomerPage.css"
import FileSectionWidget from "./widgets/FileSectionWidget"
import NextApointmentWidget from "./widgets/NextApointmentWidget"
import PlanWidget from "./widgets/PlanWidget"

const CustomersSingleCustomerPage = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const userId = location.state.userId
  const idToken = location.state.idToken
  const patient: Patient = location.state.patient
  const email = location.state.email

  const [isLoading, setIsLoading] = useState(false)

  // Actions to perform when clicking on Navbar logos and Holifya icon
  const onHolifyaLogoClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_HOME, { state: { userId, idToken } })
  }
  const onHomeIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM, { state: { userId, idToken } })
  }
  const onCustomersIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_CUSTOMERS, { state: { userId, idToken } })
  }
  const onApointmentsIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_APPOINTMENTS, { state: { userId, idToken, email } })
  }
  const onAccountIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_ACCOUNT, { state: { userId, idToken } })
  }

  const onBackToAllPatientsClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_CUSTOMERS, { state: { userId, idToken } })
  }

  const requestOptionsGET = {
    method: 'GET',
    headers: {
      'Authorization': idToken!
    }
  }

  // GET all appointments by organizer email
  async function callGetAppointmentsByOrganizerMailApi() {
    const responseUserinfo = await fetch(`${BASE_URL}/api/booking/appointments/organizer/${email}`, requestOptionsGET)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      for (const appointment of responseJson) {
        if (appointment.email === patient.email) {
          setAppointmentsAvailable(true)
          const newAppointment: Appointment = {
            date: appointment.payload.scheduled_event.start_time,
            patientName: patient.name,
            patientFamilyName: patient.familyName,
            editUrl: appointment.payload.reschedule_url,
            connectUrl: appointment.payload.scheduled_event.location.join_url,
            remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
            remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
            remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
            status: appointment.payload.status,
          }
          setAppointment(newAppointment)
        }
      }
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  const [planId, setPlanId] = useState("")

  // GET most recent plan by user id
  async function callGetMostRecentPlanByUserIdApi() {
    const responseUserinfo = await fetch(`${BASE_URL}/api/plans/user/${patient.userId}`, requestOptionsGET)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
      setKeyParameters(responseJson.data.key_points)
      setGoal(responseJson.data.goal)
      setExpertComment(responseJson.data.comment)
      setExpertKeyPoints(responseJson.data.important_key_points)
      setPlanAvailable(true)
      callGetMostRecentPlanUrlApi(responseJson.id)
      setCurrentPlanDate(handleDate(responseJson.updated_at))
      setCurrentPlanName(`${responseJson.id}.pdf`)
      setPlanId(responseJson.id)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  // GET all plans by user id
  async function callGetMostRecentPlanUrlApi(id: string) {
    const responseUserinfo = await fetch(`${BASE_URL}/api/plans/patient/${patient.userId}/plan-url?filename=${id}.pdf`, requestOptionsGET)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      setCurrentPlanUrl(responseJson.url)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  // GET all plans by user id
  async function callGetAllPlansByUserIdApi() {
    const responseUserinfo = await fetch(`${BASE_URL}/api/plans/patient/${patient.userId}/plan-urls`, requestOptionsGET)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      const oldPlansArray: Plan[] = []
      for (const plan of responseJson) {
        oldPlansArray.push({
          spanDate: plan.uploaded_at,
          name: plan.filename,
          url: plan.url,
        })
      }
      setOldPlans(oldPlansArray)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  // GET all exams urls by type and user id
  async function callGetAllExamsUrlsByTypeApi(type: ExamFolder) {
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/patient/${patient.userId}/exams-urls/${type}`, requestOptionsGET)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      if (type === ExamFolder.DNATest && responseJson.length !== 0) {
        setDnaTestUrl(responseJson[0].url)
      }
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  // GET patient emr by user id
  async function callGetPatientEmrByUserIdApi() {
    const responseUserinfo = await fetch(`${BASE_URL}/api/emr/${patient.userId}`, requestOptionsGET)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      // setGoal(responseJson.data.avatar)
      setWeight(responseJson.data.weight)
      setWaterIntake(handleWater(responseJson.data.water_intake))
      setWaterIntakeAnswer(responseJson.data.water_intake)
      setEnergy(handleEnergy(responseJson.data.energy))
      setEnergyAnswer(responseJson.data.energy)
      setWaistline(responseJson.data.waistline)
      setSleep(handleSleep(responseJson.data.sleep_quality))
      setSleepAnswer(responseJson.data.sleep_quality)
      setTraining(handleTraining(responseJson.data.training))
      setTrainingAnswer(responseJson.data.training)
      setStress(handleStressLevel(responseJson.data.stress_level))
      setStressAnswer(responseJson.data.stress_level)
      setArmCircumference(responseJson.data.arm_circumference)
      setHipsline(responseJson.data.hipsline)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  const [appointmentsAvailable, setAppointmentsAvailable] = useState(false)
  const [planAvailable, setPlanAvailable] = useState(false)
  const [oldPlans, setOldPlans] = useState<Plan[]>([])
  const [dnaTestUrl, setDnaTestUrl] = useState("")
  const [appointment, setAppointment] = useState<Appointment>({
    date: "fakedate",
    patientName: "",
    patientFamilyName: "",
    editUrl: "",
    connectUrl: "",
    remainingDays: 800,
    remainingHours: 700,
    remainingMinutes: 789,
    status: AppointmentStatus.Canceled,
  })

  const [currentPlanUrl, setCurrentPlanUrl] = useState("")
  const [currentPlanName, setCurrentPlanName] = useState("")
  const [currentPlanDate, setCurrentPlanDate] = useState("")

  const [expertComment, setExpertComment] = useState("")
  const [expertKeyPoints, setExpertKeyPoints] = useState<string[]>()
  const [keyParameters, setKeyParameters] = useState<KeyParameter[]>()

  const [goal, setGoal] = useState("")
  const [weight, setWeight] = useState("")
  const [waterIntake, setWaterIntake] = useState("")
  const [waterIntakeAnswer, setWaterIntakeAnswer] = useState("")
  const [energy, setEnergy] = useState("")
  const [energyAnswer, setEnergyAnswer] = useState("")
  const [waistline, setWaistline] = useState("")
  const [sleep, setSleep] = useState("")
  const [sleepAnswer, setSleepAnswer] = useState("")
  const [training, setTraining] = useState("")
  const [trainingAnswer, setTrainingAnswer] = useState("")
  const [stress, setStress] = useState("")
  const [stressAnswer, setStressAnswer] = useState("")
  const [armCircumference, setArmCircumference] = useState("")
  const [hipsline, setHipsline] = useState("")

  const onCreateNewPlanClick = () => {
    navigate("create-new-plan", { state: { patient, userId, idToken, email } })
  }

  const onEditPlanClick = () => {
    navigate("edit-plan", { state: { idToken, userId, patient, email, goal, keyParameters, expertKeyPoints, expertComment, planId } })
    console.log(goal)
    console.log(keyParameters)
    console.log(expertKeyPoints)
    console.log(expertComment)
  }

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
    callGetPatientEmrByUserIdApi()
    callGetAppointmentsByOrganizerMailApi()
    callGetMostRecentPlanByUserIdApi()
    callGetAllPlansByUserIdApi()
    callGetAllExamsUrlsByTypeApi(ExamFolder.DNATest)
  }, [])

  const onOpenDNATestClick = () => {
    if (dnaTestUrl !== "") {
      window.open(dnaTestUrl, "_blank")
    } else {
      setShowNoTestDialog(true)
    }
  }

  const [showNoTestDialog, setShowNoTestDialog] = useState(false)

  return (
    <div className='App Platform relative'>
      <NavBar
        onHolifyaLogoClick={onHolifyaLogoClick}
        homeLogo={HOME_LOGO}
        onHomeIconClick={onHomeIconClick}
        customersLogo={CUSTOMERS_LOGO_FOCUS}
        onCustomersIconClick={onCustomersIconClick}
        apointmentsLogo={APPOINTMENTS_LOGO}
        onApointmentsIconClick={onApointmentsIconClick}
        accountLogo={ACCOUNT_LOGO}
        onAccountIconClick={onAccountIconClick}
        isLoading={isLoading}
      />
      <div className={`platform-experts-no-navbar-container relative ${isLoading ? "visible-0-percent inactive" : ""}`}>
        <div className={`platform-experts-customers-single-patient-container ${showNoTestDialog ? "visible-30-percent inactive" : ""}`}>
          <div className="platform-experts-customers-single-patient-back-button-row">
            <div
              className="platform-experts-customers-single-patient-back-button"
              onClick={onBackToAllPatientsClick}
            >
              <div>
                <img src="/images/symbols/back-arrow.svg" />
              </div>
              <div className="platform-experts-customers-single-patient-back-button-text">
                Tutti i tuoi clienti
              </div>
            </div>
          </div>
          <div className="platform-experts-customers-single-patient-title-row">
            <div className="platform-experts-customers-single-patient-title-left-element">
              <div>
                <div className="platform-experts-customers-single-patient-title-name">
                  {patient.name} {patient.familyName}
                </div>
                <div className="platform-experts-customers-single-patient-title-plan">
                  {patient.orderType}
                </div>
                <div className="platform-experts-customers-single-patient-title-email">
                  {patient.email}
                </div>
              </div>
              {
                patient.swabCode !== null ?
                  <div className="platform-experts-customers-single-patient-title-swab-code">
                    Codice Campione: {patient.swabCode}
                  </div> : null
              }
            </div>
            <div className="platform-experts-customers-single-patient-title-right-element">
              <button
                className="platform-experts-customers-single-patient-title-reminder-button ternary-action-color-background invisible"
              >
                Reminder nuovo consulto
              </button>
              <button
                className="platform-experts-customers-single-patient-title-create-plan-button primary-action-color-background"
                onClick={onCreateNewPlanClick}
              >
                Crea nuovo piano
              </button>
            </div>
          </div>
          <div className="platform-experts-customers-single-patient-content">
            <div className="platform-experts-customers-single-patient-content-container">
              {
                appointmentsAvailable ?
                  <div>
                    <div className="height-35" />
                    <NextApointmentWidget
                      isUrgent={appointment.remainingDays < 2}
                      appointment={appointment}
                    />
                  </div> :
                  <div>
                    Non ci sono appuntamenti in programma con <span className="platform-experts-customers-single-patient-patient-name">{patient.name} {patient.familyName}</span>
                  </div>
              }
              <div className="height-20" />
              <SingleLineDivider />
              <div className="height-20" />
              {
                planAvailable ?
                  <div>
                    <PlanWidget
                      onEditPlanClick={onEditPlanClick}
                      goal={goal}
                      weight={weight}
                      waterIntake={waterIntake}
                      waterIntakeAnswer={waterIntakeAnswer}
                      energy={energy}
                      energyAnswer={energyAnswer}
                      waistline={waistline}
                      sleep={sleep}
                      sleepAnswer={sleepAnswer}
                      training={training}
                      trainingAnswer={trainingAnswer}
                      stress={stress}
                      stressAnswer={stressAnswer}
                      armCinfumference={armCircumference}
                      hipsline={hipsline}
                      currentPlan={{ spanDate: currentPlanDate, name: currentPlanName, url: currentPlanUrl }}
                      expertComment={expertComment}
                      expertKeyPoints={expertKeyPoints}
                      keyParameters={keyParameters}
                    />
                  </div> : null
              }
              <FileSectionWidget
                onOpenAnswersClick={() => navigate("answers-page", { state: { idToken, userId, patient, email } })}
                onOpenExamsClick={() => navigate("exams-page", { state: { idToken, userId, patient, email } })}
                onOpenDNATestClick={onOpenDNATestClick}
                oldPlans={oldPlans}
              />
            </div>
          </div>
        </div>
        {
          showNoTestDialog ?
            <NoTestAvailableDialog
              onClick={() => setShowNoTestDialog(false)}
              patientName={`${patient.name} ${patient.familyName}`}
            /> : null
        }
      </div>
      {
        isLoading ? <div className="width-100-percent height-100-percent center-div absolute"><LoadingSpinner /></div> : null
      }
    </div>
  )
}

export default CustomersSingleCustomerPage
