import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import LoadingSpinner from "../../components/LoadingSpinner"
import { BASE_URL } from "../../utils/Constants"
import "./css/LoginSuccess.css"

const LoginSuccess = () => {

    const navigate = useNavigate()

    const location = useLocation()
    const idToken = location.state.idToken
    const userId = location.state.userId
    const accessToken = location.state.accessToken
    const refreshToken = location.state.refreshToken
    const role = location.state.role

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        callGetMedicalUserApi()
        // setTimeout(() => {
        //         navigate("/platform-experts")
        // }, 2000 )
    }, [])

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET users info by user_id
    async function callGetMedicalUserApi() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            if (responseJson.calendly_data.calendly_invitation_status !== "pending") {
                navigate("/platform-experts", {state: {idToken, userId}})
            } else {
                navigate("/wall-page", { state: { name: "", idToken, userId, accessToken, refreshToken } })
            }
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    return (
        <div className="App justify_content_center relative">
            <div className={`login-success-container ${isLoading ? "visible-30-percent inactive" : ""}`}>
                <div className="login-success-heading">
                    Accesso in corso
                </div>
                <div className="login-success-copy">
                    Stai per essere reindirizzato alla tua area riservata
                </div>
            </div>
            {
                isLoading ? <div className="width-100-percent height-100-percent center-div absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default LoginSuccess
