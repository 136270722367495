import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ACCOUNT_LOGO, APPOINTMENTS_LOGO, CUSTOMERS_LOGO_FOCUS, HOME_LOGO, RELATIVE_PATH_PLATFORM, RELATIVE_PATH_PLATFORM_ACCOUNT, RELATIVE_PATH_PLATFORM_APPOINTMENTS, RELATIVE_PATH_PLATFORM_CUSTOMERS, RELATIVE_PATH_PLATFORM_HOME } from "../../utils/Constants"
import { handleQuizName } from "../../utils/Functions"
import NavBar from "./components/NavBar"
import { Survey } from "./components/Survey"
import "./css/CustomersSingleCustomerPageAnswersPageSingleQuiz.css"
import AnswerCard from "./widgets/components/AnswerCard"

const CustomersSingleCustomerPageAnswersPageSingleQuiz = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const patient = location.state.patient
    const idToken = location.state.idToken
    const userId = location.state.userId
    const email = location.state.email
    const survey: Survey = location.state.survey

    const [isLoading, setIsLoading] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_HOME, { state: { userId, idToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM, { state: { userId, idToken } })
    }
    const onCustomersIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_CUSTOMERS, { state: { userId, idToken } })
    }
    const onApointmentsIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_APPOINTMENTS, { state: { userId, idToken, email } })
    }
    const onAccountIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_ACCOUNT, { state: { userId, idToken } })
    }

    const onBackClick = () => {
        navigate("/platform-experts/customers/single-patient-page/answers-page", { state: { idToken, userId, patient, email } })
    }

    useEffect(() => {
    }, [])
    return (
        <div className='App Platform relative'>
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                customersLogo={CUSTOMERS_LOGO_FOCUS}
                onCustomersIconClick={onCustomersIconClick}
                apointmentsLogo={APPOINTMENTS_LOGO}
                onApointmentsIconClick={onApointmentsIconClick}
                accountLogo={ACCOUNT_LOGO}
                onAccountIconClick={onAccountIconClick}
                isLoading={isLoading}
            />
            <div className="platform-experts-no-navbar-container">
                <div className="platform-experts-customers-single-patient-answer-page-single-quiz-container">
                    <div className="platform-experts-customers-single-patient-answer-page-single-quiz-title-row">
                        <div
                            className="platform-experts-customers-single-patient-answer-page-single-quiz-back-button"
                            onClick={onBackClick}
                        >
                            <div className="platform-experts-customers-single-patient-answer-page-single-quiz-back-button-image">
                                <img src="/images/symbols/back-arrow.svg" />
                            </div>
                            <div className="platform-experts-customers-single-patient-answer-page-single-quiz-back-button-text">
                                Le risposte di {patient.name} {patient.familyName}
                            </div>
                        </div>
                        <div className="platform-experts-customers-single-patient-answer-page-single-quiz-name">
                            {handleQuizName(survey.type)}
                        </div>
                    </div>
                    <div className="platform-experts-customers-single-patient-answer-page-single-quiz-content">
                        <div className="platform-experts-customers-single-patient-answer-page-single-quiz-content-internal">
                        {
                            survey.qAndAs.map(qAndA => (
                                <AnswerCard
                                    qAndA={qAndA}
                                    date={survey.createdAt}
                                />
                            ))
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomersSingleCustomerPageAnswersPageSingleQuiz
