import CloseButton from "../../../components/CloseButton"
import { KeyParameter } from "../../../utils/Enums"
import ParameterWidgetNoValue from "../widgets/ParameterWidgetNoValue"
import "./css/KeyParametersDialog.css"

interface Props {
    onCloseButtonClick: () => void,
    isConfirmButtonDisabled: boolean,
    keyParametersSelected: KeyParameter[],
    onKeyParameterClick: (parameter: KeyParameter) => void,
    onUndoClick: () => void,
    onConfirmClick: () => void,
}

const KeyParametersDialog: React.FC<Props> = ({
    onCloseButtonClick,
    isConfirmButtonDisabled,
    keyParametersSelected,
    onKeyParameterClick,
    onUndoClick,
    onConfirmClick,
}) => {
    return (
        <div className="key-parameters-dialog-external-container center-div">
            <div className="key-parameters-dialog-internal-container">
                <div className="key-parameters-dialog-title-row">
                    <div className="key-parameters-dialog-title">
                        Parametri importanti
                    </div>
                    <CloseButton
                        onClick={onCloseButtonClick}
                    />
                </div>
                <div className="key-parameters-dialog-copy">
                    Seleziona i parametri che il tuo cliente deve avere a mente
                </div>
                <div className="key-parameters-dialog-widgets-container">
                    <ParameterWidgetNoValue
                        name="Peso"
                        image="/images/emojis/weight-icon-1.svg"
                        isSelected={keyParametersSelected.includes(KeyParameter.Weight)}
                        parameter={KeyParameter.Weight}
                        onClick={onKeyParameterClick}
                    />
                    <ParameterWidgetNoValue
                        name="Acqua"
                        image="/images/emojis/water-icon-1.svg"
                        isSelected={keyParametersSelected.includes(KeyParameter.Water)}
                        parameter={KeyParameter.Water}
                        onClick={onKeyParameterClick}
                    />
                    <ParameterWidgetNoValue
                        name="Energia"
                        image="/images/emojis/energy-icon-1.svg"
                        isSelected={keyParametersSelected.includes(KeyParameter.Energy)}
                        parameter={KeyParameter.Energy}
                        onClick={onKeyParameterClick}
                    />
                    <ParameterWidgetNoValue
                        name="Girovita"
                        image="/images/emojis/girovita-icon-1.svg"
                        isSelected={keyParametersSelected.includes(KeyParameter.WaistLine)}
                        parameter={KeyParameter.WaistLine}
                        onClick={onKeyParameterClick}
                    />
                    <ParameterWidgetNoValue
                        name="Sonno"
                        image="/images/emojis/sleep-icon-1.svg"
                        isSelected={keyParametersSelected.includes(KeyParameter.Sleep)}
                        parameter={KeyParameter.Sleep}
                        onClick={onKeyParameterClick}
                    />
                    <ParameterWidgetNoValue
                        name="Circ. fianchi"
                        image="/images/emojis/hips-icon-1.svg"
                        isSelected={keyParametersSelected.includes(KeyParameter.HipsLine)}
                        parameter={KeyParameter.HipsLine}
                        onClick={onKeyParameterClick}
                    />
                    <ParameterWidgetNoValue
                        name="Circ. braccio"
                        image="/images/emojis/arm-icon-1.svg"
                        isSelected={keyParametersSelected.includes(KeyParameter.ArmCircunference)}
                        parameter={KeyParameter.ArmCircunference}
                        onClick={onKeyParameterClick}
                    />
                    <ParameterWidgetNoValue
                        name="Allenamento"
                        image="/images/emojis/training-icon-1.svg"
                        isSelected={keyParametersSelected.includes(KeyParameter.Training)}
                        parameter={KeyParameter.Training}
                        onClick={onKeyParameterClick}
                    />
                    <ParameterWidgetNoValue
                        name="Livello di stress"
                        image="/images/emojis/stress-icon-1.svg"
                        isSelected={keyParametersSelected.includes(KeyParameter.StressLevel)}
                        parameter={KeyParameter.StressLevel}
                        onClick={onKeyParameterClick}
                    />
                </div>
                <div className="key-parameters-dialog-buttons-row">
                    <button
                        className="key-parameters-dialog-undo-button standard-uppercase-text"
                        onClick={onUndoClick}
                    >
                        Annulla
                    </button>
                    <button
                        className={`key-parameters-dialog-confirm-button standard-uppercase-text primary-action-color-background ${isConfirmButtonDisabled ? "" : "pointer"}`}
                        onClick={onConfirmClick}
                        disabled={isConfirmButtonDisabled}
                    >
                        Conferma
                    </button>
                </div>
            </div>
        </div>
    )
}

export default KeyParametersDialog
