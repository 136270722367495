import "./css/NavBar.css"

interface Props {
    onHolifyaLogoClick: () => void,
    homeLogo: string,
    onHomeIconClick: () => void,
    customersLogo: string,
    onCustomersIconClick: () => void,
    apointmentsLogo: string,
    onApointmentsIconClick: () => void,
    accountLogo: string,
    onAccountIconClick: () => void,
    isLoading: boolean,
}

const NavBar: React.FC<Props> = ({
    onHolifyaLogoClick,
    homeLogo,
    onHomeIconClick,
    customersLogo,
    onCustomersIconClick,
    apointmentsLogo,
    onApointmentsIconClick,
    accountLogo,
    onAccountIconClick,
    isLoading,
}) => {
    return (
        <div
            className={`vertical-navbar`}
        >
            <div className="vertical-navbar-intenal">
                <div
                    className="pointer"
                    onClick={onHolifyaLogoClick}
                >
                    <img src="/images/holifyaLogos/holifya-logo-white.svg" />
                </div>
                <div className="navbar-categories-container">
                    <div
                        className="navbar-single-category-container"
                        onClick={onHomeIconClick}
                    >
                        <div>
                            <img src={homeLogo} />
                        </div>
                        <div className="navbar-category-text">
                            Home
                        </div>
                    </div>
                    <div
                        className="navbar-single-category-container"
                        onClick={onCustomersIconClick}
                    >
                        <div>
                            <img src={customersLogo} />
                        </div>
                        <div className="navbar-category-text">
                            Clienti
                        </div>
                    </div>
                    <div
                        className="navbar-single-category-container"
                        onClick={onApointmentsIconClick}
                    >
                        <div>
                            <img src={apointmentsLogo} />
                        </div>
                        <div className="navbar-category-text">
                            Appuntamenti
                        </div>
                    </div>
                    <div
                        className="navbar-single-category-container"
                        onClick={onAccountIconClick}
                    >
                        <div>
                            <img src={accountLogo} />
                        </div>
                        <div className="navbar-category-text">
                            Account
                        </div>
                    </div>
                </div>
                <div
                    className="invisible"
                >
                    <img src="/images/holifyaLogos/holifya-logo-white.svg" />
                </div>
            </div>
        </div>
    )
}

export default NavBar
