import "./css/SkillCard.css"

interface Props {
    skill: string,
    onClick: (skill: string) => void,
    isChecked: boolean,
}

const SkillCard: React.FC<Props> = ({
    skill,
    onClick,
    isChecked,
}) => {
    return (
        <div
            className={`${isChecked ? "skill-card-container-clicked" : "skill-card-container"}`}
            onClick={() => onClick(skill)}
        >
            <div className="skill-card-text">
                {skill}
            </div>
            {
                isChecked ?
                <div className="margin-left-10">
                    <img src="/images/symbols/x.svg"/>
                </div>: null
            }
        </div>
    )
}

export default SkillCard
