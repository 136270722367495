import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import LoadingSpinner from "../../components/LoadingSpinner"
import SingleLineDivider from "../../components/SingleLineDivider"
import { ACCOUNT_LOGO_FOCUS, APPOINTMENTS_LOGO, BASE_URL, CUSTOMERS_LOGO, HOME_LOGO, RELATIVE_PATH_PLATFORM, RELATIVE_PATH_PLATFORM_ACCOUNT, RELATIVE_PATH_PLATFORM_APPOINTMENTS, RELATIVE_PATH_PLATFORM_CUSTOMERS, RELATIVE_PATH_PLATFORM_HOME } from "../../utils/Constants"
import { CompleteAccountPageState } from "../../utils/Enums"
import SkillCard from "../registration/components/SkillCard"
import NavBar from "./components/NavBar"
import "./css/Account.css"

const Account = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const userId = location.state.userId
  const idToken = location.state.idToken

  const [isLoading, setIsLoading] = useState(false)

  // Actions to perform when clicking on Navbar logos and Holifya icon
  const onHolifyaLogoClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_HOME, { state: { userId, idToken } })
  }
  const onHomeIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM, { state: { userId, idToken } })
  }
  const onCustomersIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_CUSTOMERS, { state: { userId, idToken } })
  }
  const onApointmentsIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_APPOINTMENTS, { state: { userId, idToken, email } })
  }
  const onAccountIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_ACCOUNT, { state: { userId, idToken } })
  }

  const requestOptionsGET = {
    method: 'GET',
    headers: {
      'Authorization': idToken!
    }
  }

  async function callSyncCalendlyApi() {
    setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/sync-calendly/${userId}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': idToken!,
      },
    })
    setIsLoading(false)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    } else {
      const responseInfoJson = await responseUserinfo.json()
      console.log(responseInfoJson)
    }
  }

  // GET all patients by user_id
  async function callGetMedicalUserApi() {
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/${userId}`, requestOptionsGET)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      setEmail(responseJson.email)
      setName(responseJson.data.name)
      setFamilyName(responseJson.data.family_name)
      setPhoneNumber(responseJson.data.phone_number)
      setBio(responseJson.data.bio)
      setTitolo(responseJson.data.sex === "M" ? "Dott." : "Dott.ssa")
      setProfessionalOrder(responseJson.data.professional_order)
      setProfessionalOrderNumber(responseJson.data.professional_order_number)
      setProfessionalOrderState(responseJson.data.professional_order_state)
      setSkillsSelected(responseJson.tags)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  async function callUpdateMedicalUserApi() {
    setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/${userId}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': idToken!,
      },
      body: JSON.stringify(
        {
          data: {
            name: name,
            family_name: familyName,
            phone_number: phoneNumber,
            sex: titolo === "Dott." ? "M" : "F",
            professional_order: professionalOrder,
            professional_order_number: professionalOrderNumber,
            professional_order_state: professionalOrderState,
            bio: bio,
          },
          tags: skillsSelected,
        })
    })
    setIsLoading(false)
    if (responseUserinfo.ok) {
      const responseInfoJson = await responseUserinfo.json()
      console.log(responseInfoJson)
    } else {
      const responseInfoJson = await responseUserinfo.json()
      console.log(responseInfoJson)
    }
  }

  const [name, setName] = useState("")
  const [familyName, setFamilyName] = useState("")
  const [email, setEmail] = useState("")
  const [taxCode, setTaxCode] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")

  const [newSkill, setNewSkill] = useState("")
  const [pageState, setPageState] = useState(CompleteAccountPageState.NoEdit)

  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")

  useEffect(() => {
    callGetMedicalUserApi()
  }, [])

  const [skills, setSkills] = useState([
    "Salute gastrointestinale",
    "Immunonutrizione",
    "Dieta Mediterranea",
    "Dieta Low Carb",
    "Dieta Chetogenica",
    "Nutrizione Sportiva",
    "Nutrizione Femminile",
    "Integrazione e Nutraceutici",
    "Adattogeni e Gestione dello stress",
    "Nutricosmetica",
    "Alimentazione Vegana e Vegetariana",
    "Dieta Low FODMAP",
  ])

  const [titolo, setTitolo] = useState("")
  const [professionalOrder, setProfessionalOrder] = useState("")
  const [professionalOrderNumber, setProfessionalOrderNumber] = useState("")
  const [professionalOrderState, setProfessionalOrderState] = useState("")
  const [bio, setBio] = useState("")
  const [skillsSelected, setSkillsSelected] = useState<string[]>([])

  const onSkillCardClick = (skill: string) => {
    handleSelectedSkillsArray(skill)
    setThirdFormChanged(true)
  }

  const handleSelectedSkillsArray = (skill: string) => {
    const index = skillsSelected.indexOf(skill);
    if (index > -1) {
      setSkillsSelected([...skillsSelected.slice(0, index), ...skillsSelected.slice(index + 1)]);
    } else {
      setSkillsSelected([...skillsSelected, ...[skill]]);
    }
  }

  const onAddNewSkillClicked = () => {
    switch (pageState) {
      case CompleteAccountPageState.NoEdit:
        setPageState(CompleteAccountPageState.Edit)
        break;
      case CompleteAccountPageState.Edit: {
        setThirdFormChanged(true)
        if (newSkill !== "") {
          handleSelectedSkillsArray(newSkill)
          const newSkills = skills
          newSkills.push(newSkill)
          setSkills(newSkills)
          setNewSkill("")
        }
        setPageState(CompleteAccountPageState.NoEdit)
        break;
      }
    }
  }

  const [firstFormChanged, setFirstFormChanged] = useState(false)
  const [secondFormChanged, setSecondFormChanged] = useState(false)
  const [thirdFormChanged, setThirdFormChanged] = useState(false)

  const onChangeDataConfirmClicked = () => {
    callUpdateMedicalUserApi()
  }

  const onModifyPasswordClicked = () => {

  }

  const onChangeExpertInfoClicked = () => {
    callUpdateMedicalUserApi()
  }

  const onLogoutButtonClick = () => {
    callLogoutApi()
  }

  async function callLogoutApi() {
    setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/auth/logout`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': idToken!,
      },
      body: JSON.stringify(
        {
          email: email,
        }
      )
    })
    setIsLoading(false)
    if (responseUserinfo.status === 302) {
      const responseInfoJson = await responseUserinfo.json()
      console.log(responseInfoJson)
      navigate("/login")
    } else {
      const responseInfoJson = await responseUserinfo.json()
      console.log(responseInfoJson)
    }
  }

  return (
    <div className='App Platform relative'>
      <NavBar
        onHolifyaLogoClick={onHolifyaLogoClick}
        homeLogo={HOME_LOGO}
        onHomeIconClick={onHomeIconClick}
        customersLogo={CUSTOMERS_LOGO}
        onCustomersIconClick={onCustomersIconClick}
        apointmentsLogo={APPOINTMENTS_LOGO}
        onApointmentsIconClick={onApointmentsIconClick}
        accountLogo={ACCOUNT_LOGO_FOCUS}
        onAccountIconClick={onAccountIconClick}
        isLoading={isLoading}
      />
      <div className={`platform-experts-no-navbar-container ${isLoading ? "visible-0-percent inactive" : ""}`}>
        <div className="platform-experts-dashboard-container">
          <div className="platform-experts-dashboard-title-row">
            <div className="platform-experts-dashboard-title-container">
              <div className="platform-experts-dashboard-title-logo-container">
                <img src="/images/platformLogos/account-big-logo.svg" />
              </div>
              <div className="platform-experts-dashboard-title">
                Account
              </div>
            </div>
            <div
              className="account-page-logout-button-external-container"
              onClick={onLogoutButtonClick}
            >
              <div
                className="account-page-logout-button-internal-container standard-uppercase-text"
              >
                <div>
                  <img src="/images/symbols/logout-icon.svg" />
                </div>
                <div>
                  Logout
                </div>
              </div>
            </div>
          </div>
          <div className="platform-experts-dashboard-content-container">
            <div className="platform-experts-final-element-container">
              <button
                className="account-sync-calendly-button primary-action-color-background standard-uppercase-text"
                onClick={callSyncCalendlyApi}
              >
                Sincronizza Calendly
              </button>
              <div className="height-35" />
              <div className="account-page-form-container">
                <div className="account-page-input-container">
                  <label className="account-page-input-label">
                    Nome
                  </label>
                  <div className="account-page-input-internal-container">
                    <input
                      className="account-page-input-field s16 w400"
                      type="text"
                      placeholder="Mario"
                      value={name}
                      onChange={
                        (e) => {
                          setFirstFormChanged(true)
                          setName(e.target.value)
                        }
                      }
                    />
                  </div>
                </div>
                <div className="height-20" />
                <div className="account-page-input-container">
                  <label className="account-page-input-label">
                    Cognome
                  </label>
                  <div className="account-page-input-internal-container">
                    <input
                      className="account-page-input-field s16 w400"
                      type="text"
                      placeholder="Esempio"
                      value={familyName}
                      onChange={
                        (e) => {
                          setFirstFormChanged(true)
                          setFamilyName(e.target.value)
                        }
                      }
                    />
                  </div>
                </div>
                <div className="height-20" />
                <div className="account-page-input-container">
                  <label className="account-page-input-label">
                    Indirizzo e-mail
                  </label>
                  <div className="account-page-input-internal-container">
                    <input
                      className="account-page-input-field s16 w400"
                      type="text"
                      placeholder="esempio@mail.com"
                      value={email}
                      onChange={
                        (e) => setEmail(e.target.value)
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="height-20" />
                <div className="account-page-input-container">
                  <label className="account-page-input-label">
                    Codice fiscale
                  </label>
                  <div className="account-page-input-internal-container">
                    <input
                      className="account-page-input-field s16 w400"
                      type="text"
                      placeholder="XXXYYYZZXZZYXXXZ"
                      value={taxCode}
                      onChange={
                        (e) => {
                          setFirstFormChanged(true)
                          setTaxCode(e.target.value)
                        }
                      }
                    />
                  </div>
                </div>
                <div className="height-20" />
                <div className="account-page-input-container">
                  <label className="account-page-input-label">
                    Numero di telefono
                  </label>
                  <div className="account-page-input-internal-container">
                    <input
                      className="account-page-input-field s16 w400"
                      type="text"
                      placeholder="PHONE NUMBER"
                      value={phoneNumber}
                      onChange={
                        (e) => {
                          setFirstFormChanged(true)
                          setPhoneNumber(e.target.value)
                        }
                      }
                    />
                  </div>
                </div>
                <div className="height-20" />
                <button
                  className={`account-confirm-button standard-uppercase-text primary-action-color-background ${firstFormChanged ? "pointer" : ""}`}
                  disabled={!firstFormChanged}
                  onClick={onChangeDataConfirmClicked}
                >
                  Conferma
                </button>
              </div>
              <div className="height-35" />
              <div className="account-page-modify-password-container">
                <div className="account-page-heading">
                  Modifica password
                </div>
                <div className="height-20" />
                <div className="account-page-input-container">
                  <label className="account-page-input-label">
                    Password
                  </label>
                  <div className="account-page-input-internal-container">
                    <input
                      className="account-page-input-field s16 w400"
                      type="password"
                      placeholder="Password Attuale"
                      value={password}
                      onChange={
                        (e) => {
                          setSecondFormChanged(true)
                          setPassword(e.target.value)
                        }
                      }
                    />
                  </div>
                </div>
                <div className="height-20" />
                <div className="account-page-password-hint">
                  Una password valida è composta da almeno 8 caratteri di cui: almeno una Maiuscola, una minuscola, un numero e un simbolo speciale (!$%& ...)
                </div>
                <div className="height-20" />
                <div className="account-page-input-container">
                  <label className="account-page-input-label">
                    Nuova password
                  </label>
                  <div className="account-page-input-internal-container">
                    <input
                      className="account-page-input-field s16 w400"
                      type="password"
                      placeholder="Nuova password"
                      value={newPassword}
                      onChange={
                        (e) => {
                          setSecondFormChanged(true)
                          setNewPassword(e.target.value)
                        }
                      }
                    />
                  </div>
                </div>
                <div className="height-20" />
                <button
                  className={`account-confirm-button standard-uppercase-text primary-action-color-background ${secondFormChanged ? "pointer" : ""}`}
                  disabled={!secondFormChanged}
                  onClick={onModifyPasswordClicked}
                >
                  Modifica password
                </button>
              </div>
              <div className="height-35" />
              <div className="height-20" />
              <div className="account-page-expert-area-container">
                <div className="account-page-professional-info-heading">
                  Informazioni professionali
                </div>
                <SingleLineDivider />
                <div className="height-35" />
                <div className="account-page-input-container">
                  <label className="account-page-input-label">
                    Titolo
                  </label>
                  <div className="account-page-input-internal-container">
                    <input
                      className="account-page-input-field s16 w400"
                      type="text"
                      placeholder="Titolo"
                      value={titolo}
                      onChange={
                        (e) => {
                          setThirdFormChanged(true)
                          setTitolo(e.target.value)
                        }
                      }
                    />
                  </div>
                </div>
                <div className="height-20" />
                <div className="account-page-input-container">
                  <label className="account-page-input-label">
                    Iscritto all'ordine
                  </label>
                  <div className="account-page-input-internal-container">
                    <input
                      className="account-page-input-field s16 w400"
                      type="text"
                      placeholder="Ordine"
                      value={professionalOrder}
                      onChange={
                        (e) => {
                          setThirdFormChanged(true)
                          setProfessionalOrder(e.target.value)
                        }
                      }
                    />
                  </div>
                </div>
                <div className="height-20" />
                <div className="account-page-input-container">
                  <label className="account-page-input-label">
                    Numero di iscrizione all'ordine
                  </label>
                  <div className="account-page-input-internal-container">
                    <input
                      className="account-page-input-field s16 w400"
                      type="text"
                      placeholder="Numero di iscrizione"
                      value={professionalOrderNumber}
                      onChange={
                        (e) => {
                          setThirdFormChanged(true)
                          setProfessionalOrderNumber(e.target.value)
                        }
                      }
                    />
                  </div>
                </div>
                <div className="height-20" />
                <div className="account-page-input-container">
                  <label className="account-page-input-label">
                    Ordine di
                  </label>
                  <div className="account-page-input-internal-container">
                    <input
                      className="account-page-input-field s16 w400"
                      type="text"
                      placeholder="Provincia dell'ordine"
                      value={professionalOrderState}
                      onChange={
                        (e) => {
                          setThirdFormChanged(true)
                          setProfessionalOrderState(e.target.value)
                        }
                      }
                    />
                  </div>
                </div>
                <div className="height-20" />
              </div>
              <div className="account-page-skills-area">
                <div className="account-page-skills-area-heading">
                  Aree di competenza
                </div>
                <div className="height-20" />
                <div className="account-page-skills-container">
                  {
                    skills.map(skill => (
                      <SkillCard
                        key={skills.indexOf(skill)}
                        skill={skill}
                        onClick={onSkillCardClick}
                        isChecked={skillsSelected.includes(skill)}
                      />
                    ))
                  }
                  {
                    skillsSelected.map(skill => (
                      !skills.includes(skill) ?
                        <SkillCard
                          key={skills.indexOf(skill)}
                          skill={skill}
                          onClick={onSkillCardClick}
                          isChecked={skillsSelected.includes(skill)}
                        /> : null
                    ))
                  }
                </div>
                <div className="height-20" />
                {
                  pageState === "edit" ?
                    <div className="account-page-input-container">
                      <label className="account-page-input-label">
                        Specializzazione
                      </label>
                      <div className="account-page-input-internal-container">
                        <input
                          className="account-page-input-field s16 w400"
                          type="text"
                          placeholder="Inserisci nuova specializzazione"
                          onChange={
                            (e) => setNewSkill(e.target.value)
                          }
                        />
                      </div>
                      <div className="height-20" />
                    </div> : null
                }
                <button
                  className={`account-confirm-button pointer ${pageState === CompleteAccountPageState.NoEdit ? "ternary-action-color-background" : "primary-action-color-background"} standard-uppercase-text`}
                  onClick={onAddNewSkillClicked}
                >
                  Aggiungi nuova
                </button>
                <div className="height-20" />
              </div>
              <div className="height-20" />
              <div className="account-page-bio-area">
                <div className="account-page-bio-heading">
                  Bio
                </div>
                <div className="height-10" />
                <textarea
                  className="account-page-text-area"
                  value={bio}
                  onChange={(e) => {
                    setThirdFormChanged(true)
                    setBio(e.target.value)
                  }
                  }
                  placeholder="Racconta che professionista sei in massimo 700 caratteri"
                />
                <div className="account-page-counter">{bio.split(" ").join("").length} su 700</div>
              </div>
              <div className="height-20" />
              <button
                className={`account-confirm-button standard-uppercase-text primary-action-color-background ${thirdFormChanged ? "pointer" : ""}`}
                disabled={!thirdFormChanged}
                onClick={onChangeExpertInfoClicked}
              >
                Conferma
              </button>
              <div className="height-20" />
            </div>
          </div>
        </div>
      </div>
      {
        isLoading ? <div className="width-100-percent height-100-percent center-div absolute"><LoadingSpinner /></div> : null
      }
    </div>
  )
}

export default Account
