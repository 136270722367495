import { Route, Routes } from 'react-router-dom';
import './App.css';
import LoginPage from './pages/login/LoginPage';
import LoginSuccess from './pages/login/LoginSuccess';
import Account from './pages/platform/Account';
import Apointments from './pages/platform/Apointments';
import Customers from './pages/platform/Customers';
import CustomersSingleCustomerPage from './pages/platform/CustomersSingleCustomerPage';
import CustomersSingleCustomerPageAnswersPage from './pages/platform/CustomersSingleCustomerPageAnswersPage';
import CustomersSingleCustomerPageAnswersPageSingleQuiz from './pages/platform/CustomersSingleCustomerPageAnswersPageSingleQuiz';
import CustomersSingleCustomerPageCreatePlan from './pages/platform/CustomersSingleCustomerPageCreatePlan';
import CustomersSingleCustomerPageEditPlan from './pages/platform/CustomersSingleCustomerPageEditPlan';
import CustomersSingleCustomerPageExamsPage from './pages/platform/CustomersSingleCustomerPageExamsPage';
import Home from './pages/platform/Home';
import CreateAccount from './pages/registration/CreateAccount';
import EmailRegistration from './pages/registration/EmailRegistration';
import EmailRegistrationCode from './pages/registration/EmailRegistrationCode';
import EmailRegistrationCompleteAccount from './pages/registration/EmailRegistrationCompleteAccount';
import WallPage from './pages/registration/WallPage';
import HomePage from './pages/temp/HomePage';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/login-success' element={<LoginSuccess />} />
        <Route path='/create-account' element={<CreateAccount />} />
        <Route path='/email-registration' element={<EmailRegistration />} />
        <Route path='/email-registration-code' element={<EmailRegistrationCode />} />
        <Route path='/email-registration-complete-account' element={<EmailRegistrationCompleteAccount />} />
        <Route path='/wall-page' element={<WallPage />} />
        <Route path='/platform-experts' element={<Home />} />
        <Route path='/platform-experts/home' element={<Home />} />
        <Route path='/platform-experts/customers' element={<Customers />} />
        <Route path='/platform-experts/customers/single-patient-page' element={<CustomersSingleCustomerPage />} />
        <Route path='/platform-experts/customers/single-patient-page/create-new-plan' element={<CustomersSingleCustomerPageCreatePlan />} />
        <Route path='/platform-experts/customers/single-patient-page/edit-plan' element={<CustomersSingleCustomerPageEditPlan />} />
        <Route path='/platform-experts/customers/single-patient-page/answers-page' element={<CustomersSingleCustomerPageAnswersPage />} />
        <Route path='/platform-experts/customers/single-patient-page/answers-page/single-survey/:surveyType' element={<CustomersSingleCustomerPageAnswersPageSingleQuiz />} />
        <Route path='/platform-experts/customers/single-patient-page/exams-page' element={<CustomersSingleCustomerPageExamsPage />} />
        <Route path='/platform-experts/apointments' element={<Apointments />} />
        <Route path='/platform-experts/account' element={<Account />} />
        <Route path="*" element={<p>Page Not Found</p>} />
      </Routes>
    </>
  );
}

export default App;
