import "./css/SetGoalWidget.css"

interface Props {
  goal: string,
  onEditGoalClick: () => void,
  showGoalDropdown: boolean,
  onDropdownChoice: (choice: string) => void,
}

const SetGoalWidget: React.FC<Props> = ({
  goal,
  onEditGoalClick,
  showGoalDropdown,
  onDropdownChoice,
}) => {
  return (
    <div className="set-goal-widget-full-container">
      <div className="set-goal-widget-title">
        Piano
      </div>
      <div className="set-goal-widget-container">
        <div className="set-goal-widget-internal-container">
          <div>
            {goal !== "" ? goal : "Seleziona"}
          </div>
          <div
            className="set-goal-widget-edit-button"
          >
            <div
              onClick={onEditGoalClick}
            >
              Modifica
            </div>
            {
              showGoalDropdown ?
                <div className="set-goal-widget-goal-dropdown">
                  <div className="set-goal-widget-goal-dropdown-internal">
                    <div
                      className="set-goal-widget-goal-dropdown-internal-element"
                      onClick={() => onDropdownChoice("Pesoforma")}
                    >
                      Pesoforma
                    </div>
                    <div
                      className="set-goal-widget-goal-dropdown-internal-element"
                      onClick={() => onDropdownChoice("Longevità")}
                    >
                      Longevità
                    </div>
                    <div
                      className="set-goal-widget-goal-dropdown-internal-element"
                      onClick={() => onDropdownChoice("Salute gastrointestinale")}
                    >
                      Salute gastrointestinale
                    </div>
                    <div
                      className="set-goal-widget-goal-dropdown-internal-element"
                      onClick={() => onDropdownChoice("Nutrizione sportiva")}
                    >
                      Nutrizione sportiva
                    </div>
                  </div>
                </div> : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetGoalWidget
