import { useNavigate } from "react-router-dom"
import "./css/HomePage.css"

const HomePage = () => {

    const navigate = useNavigate()

    return (
        <div className="App">
            <button
                className="homepage-go-to-login-button primary-action-color-background"
                onClick={() => navigate("/login")}
            >
                Vai alla login per gli esperti
            </button>
        </div>
    )
}

export default HomePage
