import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import CloseButton from "../../components/CloseButton"
import DownloadButton from "../../components/DownloadButton"
import LoadingSpinner from "../../components/LoadingSpinner"
import { ACCOUNT_LOGO, APPOINTMENTS_LOGO, BASE_URL, CUSTOMERS_LOGO_FOCUS, HOME_LOGO, RELATIVE_PATH_PLATFORM, RELATIVE_PATH_PLATFORM_ACCOUNT, RELATIVE_PATH_PLATFORM_APPOINTMENTS, RELATIVE_PATH_PLATFORM_CUSTOMERS, RELATIVE_PATH_PLATFORM_HOME, multiplidi3 } from "../../utils/Constants"
import { ExamFolder } from "../../utils/Enums"
import { handleDate } from "../../utils/Functions"
import { Exam } from "./components/Exam"
import NavBar from "./components/NavBar"
import "./css/CustomersSingleCustomerPageExamsPage.css"

const CustomersSingleCustomerPageExamsPage = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const patient = location.state.patient
    const idToken = location.state.idToken
    const userId = location.state.userId
    const email = location.state.email

    const [isLoading, setIsLoading] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_HOME, { state: { userId, idToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM, { state: { userId, idToken } })
    }
    const onCustomersIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_CUSTOMERS, { state: { userId, idToken } })
    }
    const onApointmentsIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_APPOINTMENTS, { state: { userId, idToken, email } })
    }
    const onAccountIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_ACCOUNT, { state: { userId, idToken } })
    }

    useEffect(() => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
        callGetAllExamsUrlsByTypeApi(ExamFolder.BloodTest)
        callGetAllExamsUrlsByTypeApi(ExamFolder.OtherTest)
    }, [])

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET all exams urls by type and user id
    async function callGetAllExamsUrlsByTypeApi(type: ExamFolder) {
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/patient/${patient.userId}/exams-urls/${type}`, requestOptionsGET)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
            if (type === ExamFolder.BloodTest) {
                const bloodExamsArray: Exam[] = []
                for (const exam of responseJson) {
                    bloodExamsArray.push({
                        date: exam.uploaded_at,
                        name: exam.filename,
                        url: exam.url,
                    })
                }
                if (multiplidi3.some(number => bloodExamsArray.length === number - 1)) {
                    setBloodExamsCriticalNumber(true)
                }
                setBloodExams(bloodExamsArray)
            } else if (type === ExamFolder.OtherTest) {
                console.log(responseJson)
                const otherExamsArray: Exam[] = []
                for (const exam of responseJson) {
                    otherExamsArray.push({
                        date: exam.uploaded_at,
                        name: exam.filename,
                        url: exam.url,
                    })
                }
                if (multiplidi3.some(number => otherExamsArray.length === number - 1)) {
                    setOtherExamsCriticalNumber(true)
                }
                setOtherExams(otherExamsArray)
            }
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    const [bloodExams, setBloodExams] = useState<Exam[]>([])
    const [otherExams, setOtherExams] = useState<Exam[]>([])

    const [bloodExamsCriticalNumber, setBloodExamsCriticalNumber] = useState(false)
    const [otherExamsCriticalNumber, setOtherExamsCriticalNumber] = useState(false)

    return (
        <div className='App Platform relative'>
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                customersLogo={CUSTOMERS_LOGO_FOCUS}
                onCustomersIconClick={onCustomersIconClick}
                apointmentsLogo={APPOINTMENTS_LOGO}
                onApointmentsIconClick={onApointmentsIconClick}
                accountLogo={ACCOUNT_LOGO}
                onAccountIconClick={onAccountIconClick}
                isLoading={isLoading}
            />
            <div className={`platform-experts-no-navbar-container ${isLoading ? "visible-0-percent inactive" : ""}`}>
                <div className="platform-experts-customers-single-patient-exams-page-container">
                    <div className="platform-experts-customers-single-patient-exams-page-title-row">
                        <div className="platform-experts-customers-single-patient-exams-page-title-group">
                            <div className="platform-experts-customers-single-patient-exams-page-title-group-name">
                                {patient.name} {patient.familyName}
                            </div>
                            <div className="platform-experts-customers-single-patient-exams-page-title-group-heading">
                                Esami
                            </div>
                        </div>
                        <CloseButton
                            onClick={() => navigate("/platform-experts/customers/single-patient-page", { state: { patient, userId, idToken, email } })}
                        />
                    </div>
                    <div className="platform-experts-customers-single-patient-exams-page-content">
                        <div className="platform-experts-customers-single-patient-exams-page-content-container">
                            <div className="height-35" />
                            <div className="height-35" />
                            <div className="platform-experts-customers-single-patient-exams-page-content-heading">
                                Esami del sangue
                            </div>
                            <div className="platform-experts-customers-single-patient-exams-page-blood-exams-row">
                                {
                                    bloodExams.map(exam => (
                                        <div className={`platform-experts-customers-single-patient-exams-page-exam-container`}>
                                            <div className="platform-experts-customers-single-patient-exams-page-exam-upper-element-container">
                                                <iframe
                                                    src={exam.url + "#toolbar=0"}
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </div>
                                            <div className="platform-experts-customers-single-patient-exams-page-exam-bottom-element-container">
                                                <div className="platform-experts-customers-single-patient-exams-page-exam-bottom-element-internal-container">
                                                    <div className="platform-experts-customers-single-patient-exams-page-exam-bottom-element-internal-container-date-and-name">
                                                        <div className="platform-experts-customers-single-patient-exams-page-exam-bottom-element-internal-container-date">
                                                            {handleDate(exam.date)}
                                                        </div>
                                                        <div className="platform-experts-customers-single-patient-exams-page-exam-bottom-element-internal-container-name">
                                                            {exam.name}
                                                        </div>
                                                    </div>
                                                    <DownloadButton
                                                        url={exam.url}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    bloodExamsCriticalNumber ?
                                        <div className="platform-experts-customers-single-patient-exams-page-exam-container invisible" /> : null
                                }
                            </div>
                            <div className="height-20" />
                            <div className="platform-experts-customers-single-patient-exams-page-content-heading">
                                Altri esami
                            </div>
                            <div className="platform-experts-customers-single-patient-exams-page-other-exams-row">
                                {
                                    otherExams.map(exam => (
                                        <div className="platform-experts-customers-single-patient-exams-page-exam-container">
                                            <div className="platform-experts-customers-single-patient-exams-page-exam-upper-element-container">
                                                <iframe
                                                    src={exam.url + "#toolbar=0"}
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </div>
                                            <div className="platform-experts-customers-single-patient-exams-page-exam-bottom-element-container">
                                                <div className="platform-experts-customers-single-patient-exams-page-exam-bottom-element-internal-container">
                                                    <div className="platform-experts-customers-single-patient-exams-page-exam-bottom-element-internal-container-date-and-name">
                                                        <div className="platform-experts-customers-single-patient-exams-page-exam-bottom-element-internal-container-date">
                                                            {handleDate(exam.date)}
                                                        </div>
                                                        <div className="platform-experts-customers-single-patient-exams-page-exam-bottom-element-internal-container-name">
                                                            {exam.name}
                                                        </div>
                                                    </div>
                                                    <DownloadButton
                                                        url={exam.url}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    otherExamsCriticalNumber ?
                                        <div className="platform-experts-customers-single-patient-exams-page-exam-container invisible"></div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width-100-percent height-100-percent center-div absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default CustomersSingleCustomerPageExamsPage
