import "./css/components-styles.css"

interface Props {
    onClick: () => void,
}

const BackButton: React.FC<Props> = ({
    onClick,
}) => {
  return (
    <div
        className="back-button"
        onClick={onClick}
    >
      <div className="lh0">
        <img className="lh0" src="/images/symbols/back-arrow.svg"/>
      </div>
      <div className="standard-uppercase-text">
        Indietro
      </div>
    </div>
  )
}

export default BackButton
