import { useNavigate } from "react-router-dom"
import "./css/CreateAccount.css"
import BackButton from "../../components/BackButton"
import { useState } from "react"
import Divider from "../../components/Divider"

const CreateAccount = () => {

    const navigate = useNavigate()
    const [newsletterAccepted, setNewsletterAccepted] = useState(false)

    return (
        <div className="App">
            <div className="create-account-page-buttons-row">
                <div className="create-account-page-buttons-container">
                    <BackButton
                        onClick={() => navigate("/login")}
                    />
                    <button
                        className="create-account-page-login-button secondary-action-color-background standard-uppercase-text"
                        onClick={() => navigate("/login")}
                    >
                        Accedi
                    </button>
                </div>
            </div>
            <div className="create-account-page-content">
                <div className="create-account-container">
                    <div className="create-account-internal-container">
                        <div className="create-account-heading">
                            Crea il tuo account
                        </div>
                        <div className="create-account-copy">
                            Creando un account, dichiari di aver letto e compreso le{" "}
                            <a
                                className=""
                                href={"/assets/personal-data-info.pdf"}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={
                                    {
                                        textDecoration: "underline",
                                        color: "black",
                                        fontWeight: "600",
                                        cursor: "pointer",
                                    }}
                            >
                                Informazioni relative al trattamento dei dati personali
                            </a>.
                        </div>
                        <div className="create-account-checkbox-row">
                            <div
                                className="create-account-checkbox-container"
                                onClick={() => { setNewsletterAccepted(!newsletterAccepted) }}
                            >
                                <div className={`create-account-checkbox-internal-element ${newsletterAccepted ? "" : "invisible"}`} />
                            </div>
                            <div className="create-account-checkbox-copy">
                                Dichiaro di voler ricevere newsletter o comunicazioni commerciali da parte di Holifya.<br></br>
                                <a
                                    href={"/assets/newsletter.pdf"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={
                                        {
                                            textDecoration: "underline",
                                            color: "black",
                                            fontWeight: "600",
                                            cursor: "pointer",
                                        }}
                                >
                                    Informativa relativa alla newsletter
                                </a>
                            </div>
                        </div>
                        <button
                            className="create-account-button"
                            onClick={() => navigate("/email-registration", { state: { newsletterAccepted } })}
                        >
                            Registrati con la tua e-mail
                        </button>
                        <Divider text={"o"} />
                        <button className="create-account-button relative">
                            <img className="absolute left-10" src="/images/logos/google-logo.svg" />
                            Registrati con Google
                        </button>
                        <button className="create-account-button relative">
                            <img className="absolute left-10" src="/images/logos/apple-logo.svg" />
                            Registrati con Apple
                        </button>
                    </div>
                </div>
            </div>
            <div className="create-account-page-buttons-row" />
        </div>
    )
}

export default CreateAccount
