import { AppointmentStatus } from "../../../utils/Enums"
import { handleDayOfMonth, handleHourAndMinutes, handleMonth } from "../../../utils/Functions"
import { Appointment } from "../components/Appointment"
import "./css/OldAppointmentWidget.css"

interface Props {
    appointment: Appointment,
}

const OldAppointmentWidget: React.FC<Props> = ({
    appointment,
}) => {
    return (
        <div className="old-appointment-widget-container">
            <div className="old-appointment-widget-internal-container">
                <div className="old-appointment-widget-upper-element-container">
                    <div className="old-appointment-widget-upper-element-heading">
                        Videoconsulto
                    </div>
                    <div className="old-appointment-widget-upper-element-date">
                        <span>{handleDayOfMonth(appointment.date)} {handleMonth(appointment.date)} <span className="old-appointment-widget-regular-font">{handleHourAndMinutes(appointment.date)}</span></span>
                    </div>
                </div>
                <div className="old-appointment-widget-bottom-element-container">
                    <div className="old-appointment-widget-bottom-left-element">
                        <div className="old-appointment-widget-bottom-element-name">
                            {appointment.patientName} {appointment.patientFamilyName}
                        </div>
                    </div>
                    <div className="old-appointment-widget-bottom-element-status-container">
                        <div className={appointment.status === AppointmentStatus.Active ? "old-appointment-widget-bottom-element-status-text" : "old-appointment-widget-bottom-element-status-text-canceled"}>
                            {appointment.status === AppointmentStatus.Active ? "Passato" : "Cancellato"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OldAppointmentWidget
