import "./css/UploadPlanWidget.css"

interface Props {
    isFileUploaded: boolean,
    onFileInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onFileInputRemove: () => void,
}

const UploadPlanWidget: React.FC<Props> = ({
    isFileUploaded,
    onFileInputChange,
    onFileInputRemove,
}) => {
    return (
        <div className={`upload-plan-widget-external-container ${isFileUploaded ? "upload-plan-widget-uploaded-file-background" : ""}`}>
            {
                !isFileUploaded ?
                    <div className="upload-plan-widget-internal-container">
                        <div className="upload-plan-widget-left-text">
                            Allega piano alimentare
                        </div>
                        <div
                            className="standard-uppercase-text pointer"
                            onClick={() => (document.querySelector(".file-input-box") as HTMLElement).click()}
                        >
                            Carica e sostiuisci
                        </div>
                        <input
                            type="file"
                            accept="application/pdf image/jpg image/jpeg"
                            className="file-input-box"
                            onChange={onFileInputChange}
                            hidden
                        />
                    </div>
                    :
                    <div className="upload-plan-widget-internal-container">
                        <div className="upload-plan-widget-left-text">
                            Piano allegato
                        </div>
                        <div
                            className="upload-plan-widget-remove-button"
                            onClick={onFileInputRemove}
                        >
                            <div className="upload-plan-widget-x-image">
                                <img src="/images/symbols/x.svg" />
                            </div>
                            <div
                                className="standard-uppercase-text"
                            >
                                Rimuovi
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default UploadPlanWidget
