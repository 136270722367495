interface Props {
    text: string;
}

const Divider: React.FC<Props> = ({
    text,
}) => {
    return (
        <div className="divider">
            <div className='divider-internal'>
                <div className="divider-line"></div>
                <span className="divider-text">
                    {text}
                </span>
                <div className="divider-line"></div>
            </div>
        </div>
    )
}

export default Divider
