import { PHARMACIES_CONFIRMATION_NUMBER } from "./Constants"
import { EnergyAnswer, KeyParameter, OrderType, QuizType, ShopifyItemSku, SleepAnswer, StressAnswer, TrainingAnswer, WaterAnswer } from "./Enums"

export const handleOrderType = (confirmationNumber: string, membershipStatus: string, order: string | undefined) => {
  if (confirmationNumber === PHARMACIES_CONFIRMATION_NUMBER) {
    return OrderType.FarmaDNATest
  } else if (membershipStatus === "trial" || membershipStatus === "active") {
    return OrderType.Membership
  } else if (order === ShopifyItemSku.DnaTest || order === ShopifyItemSku.DnaTestOld) {
    return OrderType.DNATest
  } else if (order === ShopifyItemSku.ExpertConsult || order === ShopifyItemSku.NutritionistConsult) {
    return OrderType.Consult
  } else {
    return OrderType.None
  }
}

export const handleDate = (date: string) => {
  const realDate = new Date(date)
  const formatter = new Intl.DateTimeFormat('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' });
  return formatter.format(realDate)
}

export const handleDayOfMonth = (date: string) => {
  const realDate = new Date(date)
  const day = realDate.getDate()
  return day
}

export const handleMonth = (date: string) => {
  const realDate = new Date(date)
  const day = realDate.getMonth() + 1
  switch (day) {
    case 1:
      return "gen"
    case 2:
      return "feb"
    case 3:
      return "mar"
    case 4:
      return "apr"
    case 5:
      return "mag"
    case 6:
      return "giu"
    case 7:
      return "lug"
    case 8:
      return "ago"
    case 9:
      return "set"
    case 10:
      return "ott"
    case 11:
      return "nov"
    case 12:
      return "dic"
    default:
      return ""
  }
}

export const handleHourAndMinutes = (date: string) => {
  const realDate = new Date(date)
  const hour = realDate.getHours()
  const minutes = realDate.getMinutes()
  return `${hour < 10 ? `0${hour.toString()}` : hour}:${minutes < 10 ? `0${minutes.toString()}` : minutes}`
}

export const handleDaysDifference = (date: string) => {
  const today = new Date()
  const futureDate = new Date(date)
  const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 3600 * 24))
  return differenceInDays
}

export const handleHoursDifference = (date: string) => {
  const today = new Date()
  const futureDate = new Date(date)
  const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 3600))
  return differenceInDays
}

export const handleMinutesDifference = (date: string) => {
  const today = new Date()
  const futureDate = new Date(date)
  const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 60))
  return differenceInDays
}

export const handleSleep = (sleep: SleepAnswer) => {
  switch (sleep) {
    case SleepAnswer.Great:
      return "Ottimo"
    case SleepAnswer.Good:
      return "Regolare"
    case SleepAnswer.NotGood:
      return "Non ottimale"
    case SleepAnswer.Bad:
      return "Scarso"
  }
}

export const handleWater = (waterIntake: WaterAnswer) => {
  switch (waterIntake) {
    case WaterAnswer.LessOneLiter:
      return "< 1 lt/g"
    case WaterAnswer.OneLiterOneLiterHalf:
      return "1-1,5 lt/g"
    case WaterAnswer.OneLiterHalfTwoLiters:
      return "1,5-2 lt/g"
    case WaterAnswer.MoreTwoLiters:
      return ">2 lt/g"
  }
}

export const handleEnergy = (energy: EnergyAnswer) => {
  switch (energy) {
    case EnergyAnswer.Active:
      return "Energico"
    case EnergyAnswer.Medium:
      return "A tratti scarico"
    case EnergyAnswer.Low:
      return "Scarico"
  }
}

export const handleStressLevel = (stressLevel: StressAnswer) => {
  switch (stressLevel) {
      case StressAnswer.Stressed:
          return "Stressato"
      case StressAnswer.Medium:
          return "A tratti stressato"
      case StressAnswer.NoStress:
          return "Sereno"
  }
}

export const handleTraining = (training: TrainingAnswer) => {
  switch (training) {
      case TrainingAnswer.Everyday:
          return "Ogni giorno"
      case TrainingAnswer.Frequently:
          return "3-4 / settimana"
      case TrainingAnswer.Sometimes:
          return "1-2 / settimana"
      case TrainingAnswer.Never:
          return "Raramente / mai"
  }
}

export const handleQuizName = (type: QuizType) => {
  switch (type) {
    case QuizType.QuizQ:
      return "Welcome survey"
    case QuizType.SurveyA:
      return "Anamnesi clinica"
    case QuizType.SurveyB:
      return "Anamnesi alimentare"
  }
}

export const handleParamenterName = (parameter: KeyParameter) => {
  switch (parameter) {
    case KeyParameter.Water:
      return "Acqua"
    case KeyParameter.Weight:
      return "Peso"
    case KeyParameter.Energy:
      return "Livello di energia"
    case KeyParameter.Sleep:
      return "Qualità del sonno"
    case KeyParameter.WaistLine:
      return "Circ. vita"
    case KeyParameter.HipsLine:
      return "Circ. fianchi"
    case KeyParameter.ArmCircunference:
      return "Circ. braccio"
    case KeyParameter.Training:
      return "Allenamento"
    case KeyParameter.StressLevel:
      return "Livello di stress"
  }
}

// export const handleParamenterImage = (parameter: KeyParameter, answer: SleepAnswer | WaterAnswer | EnergyAnswer) => {
//   switch (parameter) {
//       case KeyParameter.Water: {
//           switch (answer) {
//             case WaterAnswer.LessOneLiter :
//               return ""
//             case WaterAnswer.OneLiterOneLiterHalf:
//               return ""
//             case WaterAnswer.OneLiterHalfTwoLiters:
//               return ""
//             case WaterAnswer.MoreTwoLiters:
//               return ""
//           }
//           break;
//       }
//       case KeyParameter.Weight:
//           return "/images/emojis/weight-icon.svg"
//       case KeyParameter.Energy: {
//           switch (answer) {
//             case EnergyAnswer.Low:
//               return ""
//             case EnergyAnswer.Medium:
//               return ""
//             case EnergyAnswer.Active:
//               return ""
//           }
//           break;
//       }
//       case KeyParameter.Sleep: {
//           switch (answer) {
//             case SleepAnswer.Bad:
//               return ""
//             case SleepAnswer.NotGood:
//               return ""
//             case SleepAnswer.Good:
//               return ""
//             case SleepAnswer.Great:
//               return ""
//           }
//           break;
//       }
//       case KeyParameter.WaistLine:
//           return "/images/emojis/girovita-icon.svg"
//   }
// }

export const handleParamenterImage = (parameter: KeyParameter, energy?: string, waterIntake?: string, sleepQuality?: string, stressLevel?: string, trainingAnswer?: string) => {
  switch (parameter) {
    case KeyParameter.Energy:
      return handleEnergyImage(energy)
    case KeyParameter.Sleep:
      return handleSleepImage(sleepQuality)
    case KeyParameter.WaistLine:
      return "/images/emojis/girovita-icon-1.svg"
    case KeyParameter.Water:
      return handleWaterImage(waterIntake)
    case KeyParameter.Weight:
      return "/images/emojis/weight-icon-1.svg"
    case KeyParameter.HipsLine:
      return "/images/emojis/hips-icon-1.svg"
    case KeyParameter.ArmCircunference:
      return "/images/emojis/arm-icon-1.svg"
    case KeyParameter.Training:
      return handleTrainingImage(trainingAnswer)
    case KeyParameter.StressLevel:
      return handleStressImage(stressLevel)
  }
}

const handleEnergyImage = (energyAnswer?: string) => {
  switch (energyAnswer) {
      case EnergyAnswer.Active:
          return "/images/emojis/energy-icon-1.svg"
      case EnergyAnswer.Medium:
          return "/images/emojis/energy-icon-2.svg"
      case EnergyAnswer.Low:
          return "/images/emojis/energy-icon-3.svg"
      default:
          return "/images/emojis/energy-icon-1.svg"
  }
}

const handleSleepImage = (sleepAnswer?: string) => {
  switch (sleepAnswer) {
      case SleepAnswer.Great:
      case SleepAnswer.Good:
          return "/images/emojis/sleep-icon-1.svg"
      case SleepAnswer.NotGood:
      case SleepAnswer.Bad:
          return "/images/emojis/sleep-icon-2.svg"
      default:
          return "/images/emojis/sleep-icon-1.svg"
  }
}

const handleWaterImage = (waterAnswer?: string) => {
  switch (waterAnswer) {
      case WaterAnswer.LessOneLiter:
          return "/images/emojis/water-icon-1.svg"
      case WaterAnswer.OneLiterOneLiterHalf:
          return "/images/emojis/water-icon-2.svg"
      case WaterAnswer.OneLiterHalfTwoLiters:
          return "/images/emojis/water-icon-3.svg"
      case WaterAnswer.MoreTwoLiters:
          return "/images/emojis/water-icon-4.svg"
      default:
          return "/images/emojis/water-icon-1.svg"
  }
}

const handleStressImage = (stressAnswer?: string) => {
  switch (stressAnswer) {
      case StressAnswer.NoStress:
          return "/images/emojis/stress-icon-1.svg"
      case StressAnswer.Medium:
          return "/images/emojis/stress-icon-2.svg"
      case StressAnswer.Stressed:
          return "/images/emojis/stress-icon-3.svg"
      default:
          return "/images/emojis/stress-icon-1.svg"
  }
}

const handleTrainingImage = (trainingAnswer?: string) => {
  switch (trainingAnswer) {
      case TrainingAnswer.Everyday:
          return "/images/emojis/training-icon-1.svg"
      case TrainingAnswer.Frequently:
          return "/images/emojis/training-icon-2.svg"
      case TrainingAnswer.Sometimes:
          return "/images/emojis/training-icon-3.svg"
      case TrainingAnswer.Never:
          return "/images/emojis/training-icon-4.svg"
      default:
          return "/images/emojis/training-icon-1.svg"
  }
}

export const handleFileExtension = (type: string) => {
  switch (type) {
    case "application/pdf":
      return ".pdf"
    default:
      return "..."
  }
}