import { KeyParameter } from "../../../utils/Enums"
import "./css/ParameterWidgetNoValue.css"

interface Props {
    name: string,
    image: string,
    isSelected: boolean,
    parameter: KeyParameter,
    onClick: (parameter: KeyParameter) => void,
}

const ParameterWidgetNoValue: React.FC<Props> = ({
    name,
    image,
    isSelected,
    parameter,
    onClick,
}) => {
  return (
    <div
      className={`${isSelected ? "parameter-widget-no-value-container-selected" : "parameter-widget-no-value-container"}`}
      onClick={() => onClick(parameter)}
    >
      <div className="parameter-widget-no-value-internal-container">
        <div className="parameter-widget-no-value-heading">
            {name}
        </div>
        <div className="parameter-widget-no-value-image">
            <img src={image}/>
        </div>
        <div className="parameter-widget-no-value-value"/>
      </div>
    </div>
  )
}

export default ParameterWidgetNoValue
